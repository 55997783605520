import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnInit {

  recaptchaApiKey = '';

  @Input() showAcceptButton: boolean;
  @Output() returnShowAcceptButton = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.recaptchaApiKey = environment.recaptchaApiKey;
  }

  public resolved(captchaResponse: string) {
    if (!captchaResponse) {
      this.showAcceptButton = false;
    } else {
      this.showAcceptButton = true;
    }
    this.returnShowAcceptButton.emit({showAcceptButton: this.showAcceptButton});
  }

}
