import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { Subscription } from 'rxjs';
import { AdministrativeQueryFollowup } from '../../../models/administrative-query-followup';
import { UiService } from '../../../services/ui.service';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { RestAdministrativeQueryFollowupsResponse } from '../../../models/rest-responses';

@Component({
  selector: 'app-consultas-administrativas-desktop',
  templateUrl: './consultas-administrativas-desktop.component.html',
  styleUrls: ['./consultas-administrativas-desktop.component.scss'],
})
export class ConsultasAdministrativasDesktopComponent implements OnInit, OnDestroy {

  @Input() administrativeQueries: AdministrativeQuery[];
  administrativeQuery: AdministrativeQuery;
  hasFollowups: boolean = false;
  administrativeQueryFollowups: AdministrativeQueryFollowup[] = [];

  private selectedAdministrativeQuerySubscription: Subscription;
  private createdAdministrativeQueryFollowupSubscription: Subscription;


  constructor(
    private uiService: UiService, 
    private administrativeQueriesService: AdministrativeQueriesService
    ) { }

  ngOnInit() {
    this.selectedAdministrativeQuerySubscription = this.administrativeQueriesService.selectedAdministrativeQueryEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.administrativeQuery = administrativeQuery;
      this.administrativeQueryFollowups = [];
      this.administrativeQueriesService.getFollowpsByAdministrativeQueryId(administrativeQuery.id).subscribe((receivedFollowups: RestAdministrativeQueryFollowupsResponse) => {
        this.administrativeQueryFollowups = receivedFollowups.administrativeQueryFollowups;
        if (this.administrativeQueryFollowups && this.administrativeQueryFollowups.length !== 0) {
          this.hasFollowups = true;
        } else {
          this.hasFollowups = false;
        }
        this.administrativeQueriesService.goToBottomEvent.emit(true);
      }, error => {
        console.log(error['status']);
        if (error['status'] === 500) {
            this.uiService.onLoginRedirect();
        }else if (error['status'] === 401) {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        } else {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        }
      });

    });
    this.createdAdministrativeQueryFollowupSubscription = this.administrativeQueriesService.createdAdministrativeQueryFollowupEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.administrativeQueriesService.getFollowpsByAdministrativeQueryId(administrativeQuery.id).subscribe((receivedFollowups: RestAdministrativeQueryFollowupsResponse) => {
        this.administrativeQueryFollowups = receivedFollowups.administrativeQueryFollowups;
        if (this.administrativeQueryFollowups && this.administrativeQueryFollowups.length !== 0) {
          this.hasFollowups = true;
          this.administrativeQueriesService.goToBottomEvent.emit(true);
        } else {
          this.hasFollowups = false;
        }
      }, error => {
        console.log(error['status']);
        if (error['status'] === 500) {
            this.uiService.onLoginRedirect();
        }else if (error['status'] === 401) {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        } else {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.selectedAdministrativeQuerySubscription) {
      this.selectedAdministrativeQuerySubscription.unsubscribe();
    }
    if (this.createdAdministrativeQueryFollowupSubscription) {
      this.createdAdministrativeQueryFollowupSubscription.unsubscribe();
    }
  }

}
