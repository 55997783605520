
export enum ShowFileErrorType {
    SAVE_ERROR = 'save',
    FILE_OPENER_ERROR = 'fileOpener',
    DOWNLOAD_ERROR = 'downloadError',
}
export class ShowFileError {
    error: Error;
    type: ShowFileErrorType;
    fileUri: string;
    constructor(error: Error, type: ShowFileErrorType, fileUri?: string) {
        this.error = error;
        this.type = type;
        this.fileUri = fileUri;
    }
}
