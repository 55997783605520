export class IssueViewModel {
    // Basicos
    public id: number;
    public name: string;
    public phaseName: string;
    locationName: string;
    jobName: string;
    stateName: string;

    // Comentarios
    ownerComment: string;
    postsaleTechnicianComment: string;
    postsaleAdminComment: string;
    propertyDeveloperComment: string;
    stateComment: string;

    // Fechas
    createDate: string;
    repairDate: string;
    materialDate: string;
    appointmentDate: string;

    // Imagen
    photoLocalUrl: string;

    // Logica
    hasPhoto: boolean;
    ownerCanAddComment: boolean;
    ownerConform: boolean; // old editable
    stateKey: string;
    stateGroupKey: string;
}
