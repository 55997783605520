import { Phases, StatesGroups } from '@arpada/arp-lib-common-qupro';
import {HttpHeaders } from '@angular/common/http';

export class Constants {
    public static readonly OWNER_ALL_PHASES_FILTER = [Phases.POSTSALE, Phases.PRESALE, Phases.MANTENIMIENTO];
    public static readonly OWNER_ALL_STATES_GROUPS_FILTER = [
        StatesGroups.PENDING_PROCESSING,
        StatesGroups.PROCESSED_NO_DATE,
        StatesGroups.PROCESSED_DATE,
        StatesGroups.PENDING_MATERIAL,
        StatesGroups.REPAIRED_CLOSE,
        StatesGroups.NOT_APPLICABLE,
    ];
    public static readonly PDF_FILE_EXTENSION = 'pdf';
    public static readonly NATIVE_MOBILE_PLATFORM = 'cordova';
    public static readonly IOS_PLARFORM = 'ios';
    public static readonly RESPONSE_TYPE_BLOB = 'blob';

    public static ADD_HEADERS_FOR_BLOB_FILE(headers: HttpHeaders): HttpHeaders {
        headers.append('Content-Type', 'application/octet-stream');
        headers.append('Content-Disposition', 'attachment');
        return headers;
    }
}
