import { AdministrativeQuery } from "./administrative-query";

export class AdministrativeQueryFollowup {
    id: number;
    administrative_query: AdministrativeQuery;
    followup_text: string;
    entry_datetime: Date;
    followup_user_email: string;
    followup_type: string;
    filename: string;
    stored_file_uuid: string;
}