import { Injectable } from '@angular/core';
import { MessagesService } from './messages.service';
import { IssueViewModel } from '../models/issueViewModel';
import { IssueOwner, IssueCreateDataBase, AppoimentData } from '@arpada/arp-lib-common-qupro';
import { DatePipe } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class PopulateService {

    constructor(
        private messagesService: MessagesService,
        private datepipe: DatePipe,
    ) { }

    public populateToIssueViewModel(issue: IssueOwner): IssueViewModel {
        return {
            id: issue.id,
            name: issue.name,
            phaseName: this.messagesService.getLabelFromPhaseKey(issue.phase_key),
            locationName: issue.location_name,
            jobName: issue.job_name,
            stateName: issue.state_group_name,
            // Comentarios
            ownerComment: issue.owner_comment,
            postsaleTechnicianComment: issue.postsale_technician_comment,
            postsaleAdminComment: issue.postsale_admin_comment,
            propertyDeveloperComment: issue.property_developer_comment,
            stateComment: issue.state_comment,
            // Fechas
            createDate: issue.create_date,
            repairDate: issue.repair_date,
            materialDate: issue.material_date,
            appointmentDate: issue.appointment_date,
            // Imagen
            photoLocalUrl: null,
            // Logica
            hasPhoto: issue.has_photo ? true : false, // 0 o undefined return false
            ownerCanAddComment: issue.owner_can_add_comment ? true : false, // 0 o undefined return false
            ownerConform: issue.owner_conform ? true : false, // 0 o undefined return false
            stateKey: issue.state_key,
            stateGroupKey: issue.state_group_key,
        };
    }

    public updateIssueViewModelWithServiceIssue(issueToUpdate: IssueViewModel, issue: IssueOwner) {
        if (issueToUpdate && issue) {
            issueToUpdate.name = issue.name;
            issueToUpdate.phaseName = this.messagesService.getLabelFromPhaseKey(issue.phase_key);
            issueToUpdate.locationName = issue.location_name;
            issueToUpdate.jobName = issue.job_name;
            issueToUpdate.stateName = issue.state_group_name;
            // Comentarios
            issueToUpdate.ownerComment = issue.owner_comment;
            issueToUpdate.postsaleTechnicianComment = issue.postsale_technician_comment;
            issueToUpdate.postsaleAdminComment = issue.postsale_admin_comment;
            issueToUpdate.propertyDeveloperComment = issue.property_developer_comment;
            issueToUpdate.stateComment = issue.state_comment;
            // Fechas
            issueToUpdate.createDate = issue.create_date;
            issueToUpdate.repairDate = issue.repair_date;
            issueToUpdate.materialDate = issue.material_date;
            issueToUpdate.appointmentDate = issue.appointment_date;
            // Logica
            issueToUpdate.hasPhoto = issue.has_photo ? true : false;
            issueToUpdate.ownerCanAddComment = issue.owner_can_add_comment ? true : false; // 0 o undefined return false
            issueToUpdate.ownerConform = issue.owner_conform ? true : false; // 0 o undefined return false
            issueToUpdate.stateKey = issue.state_key;
            issueToUpdate.stateGroupKey = issue.state_group_key;
        }
    }

    public populateToIssueCreate(jobId: number, localizationId: number,
                                    description: string,  houseId: number, photoBase64: string): IssueCreateDataBase {
        return {
            job_id: jobId,
            name: description,
            location_id: localizationId,
            house_id: houseId,
            photo: photoBase64,
        };
    }

    public populateAppoimentViewModel(appoiment: AppoimentData): AppoimentViewModel {
        let appoimentToReturn: AppoimentViewModel = null;
        if (appoiment) {
            const timestamp: number = +appoiment.appointment_date;
            const datetime: Date = new Date(timestamp);
            appoimentToReturn = {
                date: this.datepipe.transform(datetime, 'dd/MM/yyyy'),
                dateMulti: this.datepipe.transform(datetime, 'yyyy-MM-dd'),
                time: this.datepipe.transform(datetime, 'HH:mm'),
                jobName: appoiment.job_name,
                timestamp
            };
        }
        return appoimentToReturn;
    }
}
