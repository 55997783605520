import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController, AlertController, IonInput } from '@ionic/angular';
import { InputAdministrativeQuery } from '../../../models/input-administrative-query';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { MessagesService } from '../../../services/messages.service';
import { UiService } from '../../../services/ui.service';
import { InputFollowupFileToUpload } from '../../../models/input-followup-file-to-upload';


@Component({
  selector: 'app-create-administrative-query-modal',
  templateUrl: './create-administrative-query-modal.component.html',
  styleUrls: ['./create-administrative-query-modal.component.scss'],
})
export class CreateAdministrativeQueryModalComponent implements OnInit {

  administrativeQueryToCreateFormData = {
    subject: '',
    description: '',
    categoryUniqueKey: ''
  };

  readingPhoto = false;
  base64File: string = null;
  inputFollowupFileToUpload: InputFollowupFileToUpload = null;
  fileSelected = false;
  disabledAttachButton = false;

  constructor(
    private portalPropietarioService: PortalPropietarioService,
    private messagesService: MessagesService,
    private uiService: UiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {}

  close(){
    return this.modalCtrl.dismiss({
      data: null,
      role: 'cancel'
    });

  }

  cancelFileUpload() {
    this.fileSelected = false;
    this.readingPhoto = false;
    this.disabledAttachButton = false;
    this.base64File = null;
    this.inputFollowupFileToUpload = null;
  }

  addCategory(categorySelectedUniqueKey: string) {
    this.administrativeQueryToCreateFormData.categoryUniqueKey = categorySelectedUniqueKey;
  }

  createAdministrativeQuery(fNewAdministrativeQuery: NgForm){
    // console.log('administrativeQueryToCreateFormData: ', this.administrativeQueryToCreateFormData);
    const inputAdministrativeQuery: InputAdministrativeQuery = new InputAdministrativeQuery();
    inputAdministrativeQuery.subject = this.administrativeQueryToCreateFormData.subject;
    inputAdministrativeQuery.followup_text = this.administrativeQueryToCreateFormData.description;
    inputAdministrativeQuery.category_uniquekey = this.administrativeQueryToCreateFormData.categoryUniqueKey;
    if (this.inputFollowupFileToUpload !== undefined) {
      inputAdministrativeQuery.input_filename_to_upload = this.inputFollowupFileToUpload;
    }
    return this.modalCtrl.dismiss({
      data: inputAdministrativeQuery,
      role: 'create'
    });

  }
  
  ionInputFileChangeEvent(ionInput: IonInput, event: Event): void {
    this.disabledAttachButton = true;
    ionInput.getInputElement().then((input: HTMLInputElement) => {
      if (input && input.files) {
        this.fileChangeEvent(input.files, event);
      } else {
        this.portalPropietarioService.logger.error('[CreateAdministrativeQueryModalComponent.ionInputFileChangeEvent] No existe el input o el atributo files.');
        this.uiService.presentAlert(this.messagesService.getUnexpectedErrorTitle(), null,
        this.messagesService.getCanNotGetImageErrorMessage());
      }
    }).catch((error: Error) => {
      this.portalPropietarioService.logger.error('[CreateAdministrativeQueryModalComponent.ionInputFileChangeEvent] No existe el elemento para obtener la imagen.', error);
      this.uiService.presentAlert(this.messagesService.getUnexpectedErrorTitle(), null,
                                  this.messagesService.getCanNotGetImageErrorMessage());
    });
  }

  fileChangeEvent(files: FileList, event: Event): void {
    this.readingPhoto = false;
    this.inputFollowupFileToUpload = new InputFollowupFileToUpload();
    if (files.length <= 0) {
      this.base64File = null;
    } else if (files.length > 1) {
      this.base64File = null;
      this.showErrorAlert(this.messagesService.getErrorNewIssueMultipleFilesTitle(),
                          this.messagesService.getErrorNewIssueMultipleFilesMessage());
    } else {
      this.readingPhoto = true;
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const srcText: string = fileReader.result.toString();
        this.fileSelected = true;
        this.disabledAttachButton = true;
        this.base64File = srcText;
        const arrayBase64 = this.base64File.split(',');
        this.inputFollowupFileToUpload.base64 = arrayBase64.pop();
        this.inputFollowupFileToUpload.mimetype = files[0].type;
        this.inputFollowupFileToUpload.filename = files[0].name;
        this.inputFollowupFileToUpload.file_extension = this.getFileExtension(files[0].name);
        this.readingPhoto = false;
      };
      fileReader.readAsDataURL(files[0]);
    }
  }

  showErrorAlert(title: string, message: string): void {
    const okLabel: string = this.messagesService.getOkButtonLabel();
    const errorHeader: string = this.messagesService.getErrorHeaderLabel();
    this.alertCtrl.create({
      header: errorHeader,
      subHeader: title,
      message,
      buttons: [{
        text: okLabel,
        cssClass: 'primary'
      }]
    }).then(alert => {
      alert.present();
    });
  }

  private getFileExtension(filename: string): string {
    const stringList = filename.split('.');
    return stringList.pop();
  }

}
