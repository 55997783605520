import { Component, OnInit, Input } from '@angular/core';
import { AdministrativeQuery } from '../../../models/administrative-query';

@Component({
  selector: 'app-administrative-queries',
  templateUrl: './administrative-queries.component.html',
  styleUrls: ['./administrative-queries.component.scss'],
})
export class AdministrativeQueriesComponent implements OnInit {

  @Input() administrativeQueries: AdministrativeQuery[];
  @Input() isMobile: boolean;

  constructor() { }

  ngOnInit() {
  }

}
