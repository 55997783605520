export class ThemeInfoMapService {

    private static ARPADA_THEME = {theme: 'arpada.theme', logo: 'logo-arpada.png'};
    private static ACTIVITAS_THEME = {theme: 'activitas.theme', logo: 'logo-activitas.png'};
    private static Q21_THEME = {theme: 'q21.theme', logo: 'logo-q21.png'};
    private static AEDAS_THEME = {theme: 'aedashome.theme', logo: 'logo-aedashome.png'};
    private static VIA_CELERE_THEME = {theme: 'viacelere.theme', logo: 'logo-viacelere.png'};
    private static ESPACIO_THEME = {theme: 'espacio.theme', logo: 'logo-espacio.png'};
    private static PRYCONSA_THEME = {theme: 'pryconsa.theme', logo: 'logo-pryconsa.png'};
    private static GRUPOLAR_THEME = {theme: 'grupolar.theme', logo: 'logo-grupolar.png'};
    private static FERROCARRIL_THEME = {theme: 'ferrocarril.theme', logo: 'logo-ferrocarril.png'};
    private static AVALON_THEME = {theme: 'avalon.theme', logo: 'logo-avalon.png'};
    private static METROVACESA_THEME = {theme: 'metrovacesa.theme', logo: 'logo-metrovacesa.png'};

    public static THEME_HOST_MAP = {
        // ARPADA
        'arpada-dev.myvivienda.com': ThemeInfoMapService.ARPADA_THEME,
        'arpada-test.myvivienda.com': ThemeInfoMapService.ARPADA_THEME,
        'arpada.myvivienda.es':  ThemeInfoMapService.ARPADA_THEME,
        'arpada.myvivienda.com':  ThemeInfoMapService.ARPADA_THEME,

        // Activitas
        'activitas-dev.myvivienda.com': ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas-test.myvivienda.com': ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas.myvivienda.com':  ThemeInfoMapService.ACTIVITAS_THEME,
        'activitas.myvivienda.es':  ThemeInfoMapService.ACTIVITAS_THEME,

        // Q21
        'q21-dev.myvivienda.com': ThemeInfoMapService.Q21_THEME,
        'q21-test.myvivienda.com': ThemeInfoMapService.Q21_THEME,
        'q21.myvivienda.com':  ThemeInfoMapService.Q21_THEME,
        'q21.myvivienda.es':  ThemeInfoMapService.Q21_THEME,

        // Aedas
        'aedas-dev.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedashome-dev.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedas-test.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedashome-test.myvivienda.com': ThemeInfoMapService.AEDAS_THEME,
        'aedas.myvivienda.com':  ThemeInfoMapService.AEDAS_THEME,
        'aedas.myvivienda.es':  ThemeInfoMapService.AEDAS_THEME,
        'aedashome.myvivienda.com':  ThemeInfoMapService.AEDAS_THEME,
        'aedashome.myvivienda.es':  ThemeInfoMapService.AEDAS_THEME,

        // Omega40
        'omega40.myvivienda.com':  ThemeInfoMapService.ARPADA_THEME,
        'omega40.myvivienda.es':  ThemeInfoMapService.ARPADA_THEME,

        // Via Celere
        'celere-dev.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-dev.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-dev.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-dev.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-test.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-test.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere-test.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere-test.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,
        'viacelere.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere.myvivienda.com': ThemeInfoMapService.VIA_CELERE_THEME,
        'celere.myvivienda.es': ThemeInfoMapService.VIA_CELERE_THEME,

        // Espacio
        'espacio.myvivienda.com': ThemeInfoMapService.ESPACIO_THEME,
        'espacio-test.myvivienda.com': ThemeInfoMapService.ESPACIO_THEME,
        'espacio-dev.myvivienda.com': ThemeInfoMapService.ESPACIO_THEME,

        // Pryconsa
        'pryconsa.myvivienda.com': ThemeInfoMapService.PRYCONSA_THEME,
        'pryconsa-test.myvivienda.com': ThemeInfoMapService.PRYCONSA_THEME,
        'pryconsa-dev.myvivienda.com': ThemeInfoMapService.PRYCONSA_THEME,

        // Grupolar
        'grupolar.myvivienda.com': ThemeInfoMapService.GRUPOLAR_THEME,
        'grupolar-test.myvivienda.com': ThemeInfoMapService.GRUPOLAR_THEME,
        'grupolar-dev.myvivienda.com': ThemeInfoMapService.GRUPOLAR_THEME,

        // Ferrocarril
        'ferrocarril.myvivienda.com': ThemeInfoMapService.FERROCARRIL_THEME,
        'ferrocarril-test.myvivienda.com': ThemeInfoMapService.FERROCARRIL_THEME,
        'ibejarano-propietario.ferrocarril.myvivienda.dev-aks.grupoarpada.com': ThemeInfoMapService.FERROCARRIL_THEME,

        // Avalon
        'avalonproperties.myvivienda.com': ThemeInfoMapService.AVALON_THEME,
        'avalonproperties-test.myvivienda.com': ThemeInfoMapService.AVALON_THEME,
        'ibejarano-propietario.avalonproperties.myvivienda.dev-aks.grupoarpada.com': ThemeInfoMapService.AVALON_THEME,

        // Metrovacesa
        'metrovacesa.myvivienda.com': ThemeInfoMapService.METROVACESA_THEME,
        'metrovacesa-test.myvivienda.com': ThemeInfoMapService.METROVACESA_THEME,
        'ibejarano-propietario.metrovacesa.myvivienda.dev-aks.grupoarpada.com': ThemeInfoMapService.METROVACESA_THEME,

    };

    public static THEME_PARAM_MAP = {
        arpada: ThemeInfoMapService.ARPADA_THEME,
        activitas: ThemeInfoMapService.ACTIVITAS_THEME,
        q21: ThemeInfoMapService.Q21_THEME,
        aedashome: ThemeInfoMapService.AEDAS_THEME,
        aedas: ThemeInfoMapService.AEDAS_THEME,
        viacelere: ThemeInfoMapService.VIA_CELERE_THEME,
        celere: ThemeInfoMapService.VIA_CELERE_THEME,
        espacio: ThemeInfoMapService.ESPACIO_THEME,
        pryconsa: ThemeInfoMapService.PRYCONSA_THEME,
        grupolar: ThemeInfoMapService.GRUPOLAR_THEME,
        ferrocarril: ThemeInfoMapService.FERROCARRIL_THEME,
        avalon: ThemeInfoMapService.AVALON_THEME,
        metrovacesa: ThemeInfoMapService.METROVACESA_THEME,
    };
}
