import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UiService } from '../../services/ui.service';
import { MessagesService } from '../../services/messages.service';
import { QuproServiceError, QuproServiceErrorType } from '@arpada/arp-lib-common-qupro';
import { Platform, ModalController } from '@ionic/angular';
import { PortalPropietarioService } from 'src/app/services/portal-propietario.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  recaptchaApiKey = '';
  disableAcceptButton = true;
  resetPasswordData = {
    email: '',
    didResetPasswordCallback: (didResetPassword: boolean) => {},
  };
  showLoading = false;
  resetPasswordUrl: string;
  baseUrlForResetPassword: string;
  private captchaResponse: string = null;

  constructor(
    private uiService: UiService,
    private messagesService: MessagesService,
    private platform: Platform,
    private modalController: ModalController,
    private portalPropietarioService: PortalPropietarioService,
  ) {
    let resetPasswordUrlBase: string;
    if (this.isWeb() && (location && location.origin)) {
      resetPasswordUrlBase = location.origin;
    } else {
      resetPasswordUrlBase = environment.resetPasswordUrl;
    }
    if (resetPasswordUrlBase && !resetPasswordUrlBase.endsWith('/')) {
      resetPasswordUrlBase += '/';
    }
    this.baseUrlForResetPassword = resetPasswordUrlBase;
    this.resetPasswordUrl = resetPasswordUrlBase + 'login';
  }

  ngOnInit() {
    this.recaptchaApiKey = environment.recaptchaApiKey;
  }

  resolved(captchaResponse: string) {
    this.disableAcceptButton = !captchaResponse;
    this.captchaResponse = captchaResponse;
  }

  resetPassword() {
    this.disableAcceptButton = true;
    this.showLoading = true;
    if (!this.resetPasswordData.email) {
      this.uiService.showSimpleMiddleToast(this.messagesService.getEmailValidationMessageOnResetPassword(), 2000);
      return;
    }
    const loginUser: string = this.resetPasswordData.email;
    this.portalPropietarioService.secureResetPassword(loginUser, this.resetPasswordUrl, this.captchaResponse, this.baseUrlForResetPassword).subscribe(resp => {
      // console.log('resp: ', resp);
      this.showLoading = false;
      if (this.resetPasswordData.didResetPasswordCallback) {
        this.resetPasswordData.didResetPasswordCallback(true);
      }
      try {
        this.modalController.dismiss({
          dismissed: true,
        });
      } catch (error) {
        // Nothing to do
      }
    },
    (error: QuproServiceError) => {
      this.showLoading = false;
      this.portalPropietarioService.logger.error('[ResetPasswordComponent] Error al intentar resetear la contraseña.', error);
      let errorMessage: string = this.messagesService.getErrorMessageResetPassword();
      if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
        errorMessage = error.message;
      }
      this.uiService.presentAlert(this.messagesService.getErrorHeaderResetPassword(),
                                  this.messagesService.getErrorSubHeaderResetPassword(),
                                  errorMessage);
    });
  }

  private isWeb(): boolean {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

}
