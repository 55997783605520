import { Component, Input, OnInit } from '@angular/core';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';

@Component({
  selector: 'app-administrative-query',
  templateUrl: './administrative-query.component.html',
  styleUrls: ['./administrative-query.component.scss'],
})
export class AdministrativeQueryComponent implements OnInit {

  @Input() administrativeQuery: AdministrativeQuery;
  hasFollowups: boolean = false;
  isStatusClosed: boolean = false;
  isStatusPending: boolean = false;
  isStatusRespond: boolean = false;

  constructor(private administrativeQueriesService: AdministrativeQueriesService) { }

  ngOnInit() {
    this.setStatus(this.administrativeQuery);
  }

  openFollowups(administrativeQuery: AdministrativeQuery) {
    this.administrativeQueriesService.selectedAdministrativeQueryEvent.emit(administrativeQuery);
    // this.administrativeQueriesService.goToBottomEvent.emit(true);
  }

  private setStatus(administrativeQuery: AdministrativeQuery) {
    if (administrativeQuery.status === 'closed') {
      this.isStatusClosed = true;
    }
    if (administrativeQuery.status === 'pending') {
      this.isStatusPending = true;
    }
    if (administrativeQuery.status === 'respond') {
      this.isStatusRespond = true;
    }
  }

}
