import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Platform, ModalController } from '@ionic/angular';
import { PortalModalComponent } from '@arpada/arp-shared-comp-qupro/libraries/components/portal-modal/portal-modal.component';


export interface SignedData {
  base64: string;
  mimetype: string;
}

@Component({
  selector: 'signature-widget',
  templateUrl: './signature-widget.component.html',
  styleUrls: ['./signature-widget.component.scss'],
})
export class SignatureWidgetComponent implements OnInit, OnDestroy {

  @ViewChild('signaturePad', {static: true}) signaturePad: SignaturePad;
  @ViewChild('signaturePad', {static: true}) signaturePadHtml: any;

  @Input() infoText: string;
  @Output() public signed: EventEmitter<SignedData> = new EventEmitter<SignedData>();

  signaturePadOptions = {
    canvasWidth: 0,
    canvasHeight: 0
  };


  screenOrientationText = 'portrait';

  constructor(
    private screenOrientation: ScreenOrientation,
    private platform: Platform,
    private modalController: ModalController,
  ) {
    this.recalculateOrientation();
    this.screenOrientation.onChange().subscribe(() => {
      this.recalculateOrientation();
      this.recalculateCanvasSignatureSize();
      this.clearSignature();
    });
  }

  ngOnInit() {
    this.recalculateCanvasSignatureSize();
    if (this.isMobile()) {
      this.requestFullScreen();
    }
  }

  ngOnDestroy() {
    if (this.isMobile()) {
      this.exitFullscreen();
    }
  }

  private requestFullScreen() {
    const elem = document.documentElement;
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } catch (error){}
  }

  private exitFullscreen() {
    try{
      if(document.exitFullscreen){
        document.exitFullscreen();
      }
    } catch (error){}
  }


  recalculateOrientation() {
    if (this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE ||
      this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
      this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY) {
      this.screenOrientationText = 'landscape';
    } else {
      this.screenOrientationText = 'portrait';
    }
  }

  recalculateCanvasSignatureSize() {
    setTimeout(() => {
      const widthString: string = getComputedStyle(this.signaturePadHtml.elementRef.nativeElement).width;
      const heightString: string = getComputedStyle(this.signaturePadHtml.elementRef.nativeElement).height;
      const widthNumber: number = parseInt(widthString.replace('px', ''), 10);
      const heightNumber: number = parseInt(heightString.replace('px', ''), 10);
      this.signaturePadOptions = {
        canvasWidth: widthNumber,
        canvasHeight: heightNumber,
      };
      this.signaturePad.set('canvasWidth', this.signaturePadOptions.canvasWidth);
      this.signaturePad.set('canvasHeight', this.signaturePadOptions.canvasHeight);
    }, 200);
  }

  isNativeMobile() {
    return this.platform.is('cordova');
  }

  isMobile() {
    return this.platform.is('cordova') || this.platform.is('mobileweb');
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  doSignature() {
    const signatureString = this.signaturePad.toDataURL();
    const splitedSignature: string[] = signatureString.split(',');
    const base64Signature: string = splitedSignature[1];
    const headerSignature: string = splitedSignature[0];
    const base64Mimetype: string = headerSignature.replace('data:', '').replace(';base64', '');
    if (this.signed) {
      this.signed.next({
        base64: base64Signature,
        mimetype: base64Mimetype,
      });
    }
    this.modalController.dismiss({
      dismissed: true
    });
  }

  clearSignature() {
    this.signaturePad.clear();
  }
}
