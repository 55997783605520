import { Injectable, Inject } from '@angular/core';
import { ToastController, AlertController, Platform } from '@ionic/angular';
import { QuproObservable, IssueOwner, QuproServiceError, QuproServiceErrorType,
         LocalStorageQuproLogger } from '@arpada/arp-lib-common-qupro';
import { Subscription } from 'rxjs';
import { MessagesService } from './messages.service';
import { Router } from '@angular/router';
import { Constants } from '../models/constants';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { saveAs as fileSaveAs } from 'file-saver';
import { ShowFileError, ShowFileErrorType } from '../models/show.file.error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginRedirectObservableEvent } from '@arpada/arp-shared-comp-qupro/libraries/interfaces/login.redirect.observable.event';

@Injectable({
  providedIn: 'root'
})
export class UiService implements LoginRedirectObservableEvent {

  constructor(@Inject(ToastController) private toastController: ToastController,
              public alertCtrl: AlertController,
              private messagesService: MessagesService,
              private router: Router,
              private platform: Platform,
              private fileService: File,
              private fileOpener: FileOpener,
              private httpClient: HttpClient,
              public logger: LocalStorageQuproLogger,
  ) { }
  public static readonly LOGIN_REDIRECT_EVENT: QuproObservable<void> = new QuproObservable<void>();
  public static readonly ISSUE_CREATE_EVENT: QuproObservable<IssueOwner[]> = new QuproObservable<IssueOwner[]>();
  public static readonly ISSUE_UPDATE_EVENT: QuproObservable<IssueOwner[]> = new QuproObservable<IssueOwner[]>();

  private loginredirectAlert: HTMLIonAlertElement = null;
  private isShowingLoginRedirectAlert = false;

  public async showSimpleMiddleToast(message: string, duration: number) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'middle'
    });
    toast.present();
  }

  public async showSimpleBottomToast(message: string, duration: number) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom'
    });
    toast.present();
  }

  public async presentAlert(header: string, subHeader: string, message: string): Promise<HTMLIonAlertElement> {
    const okButtonLabel: string = this.messagesService.getOkButtonLabel();
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [        {
          text: okButtonLabel,
          handler: (blah) => {
          }
        }
      ]
    });
    await alert.present();
    return alert;
  }

  private async presentLoginRedirecAlert(): Promise<HTMLIonAlertElement> {
    if (!this.loginredirectAlert && !this.isShowingLoginRedirectAlert) {
      this.isShowingLoginRedirectAlert = true;
      try {
        const okButtonLabel: string = this.messagesService.getOkButtonLabel();
        const header: string = this.messagesService.getLoginRedirectAlertHeader();
        const subHeader: string = this.messagesService.getLoginRedirectAlertSubHeader();
        const message: string = this.messagesService.getLoginRedirectAlertMessage();
        this.loginredirectAlert = await this.alertCtrl.create({
          header,
          subHeader,
          message,
          buttons: [        {
              text: okButtonLabel,
              handler: (blah) => {
                this.loginredirectAlert = null;
                this.isShowingLoginRedirectAlert = false;
              }
            }
          ]
        });
        await this.loginredirectAlert.present();
      } catch (error) {
        this.loginredirectAlert = null;
        this.isShowingLoginRedirectAlert = false;
      }
    }
    return this.loginredirectAlert;
  }

  private getBase64DataHeader(datatype: string): string {
    return 'data:' + datatype + ';base64';
  }

  private convertBase64ToBlob(b64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  private downloadFileOnBrowser(filename: string, datatype: string, base64Data: string) {
    fileSaveAs(this.convertBase64ToBlob(base64Data, datatype), filename);
  }

  private saveAndOpenFile(base64Data: string, filename: string, datatype: string): Promise<any> {
    if (!this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) { throw new Error('Expected mobile device but was not found.'); }
    return new Promise<any>((resolve: (toResolve: any) => void, reject: (toReject: ShowFileError) => void) => {
      const writeDirectory = this.platform.is(Constants.IOS_PLARFORM) ?
      this.fileService.dataDirectory : this.fileService.externalDataDirectory;
      const fileUri = writeDirectory + filename;
      this.fileService.writeFile(writeDirectory, filename,
        this.convertBase64ToBlob(base64Data, this.getBase64DataHeader(datatype)),
        {replace: true}).then((file: any) => {
          /* Example of file response: {
          "isFile": true,
          "isDirectory": false,
          "name": "informe.txt",
          "fullPath": "/informe.txt",
          "filesystem": "<FileSystem: files-external>",
          "nativeURL": "file:///storage/emulated/0/Android/data/io.ionic.devapp/files/informe.txt"
          } */
          this.fileOpener.open(fileUri, datatype).then((response: any) => {
            resolve(response);
          }).catch((error: Error) => {
            reject(new ShowFileError(error, ShowFileErrorType.FILE_OPENER_ERROR, fileUri));
          });
      }).catch((error: Error) => {
        reject(new ShowFileError(error, ShowFileErrorType.SAVE_ERROR));
      });
    });
  }

  private completeDownloadActionOnMobile(downloadFileName: string, downloadDatatype: string,
                                         downloadBase64: string, loggerName: string): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      this.saveAndOpenFile(downloadBase64, downloadFileName, downloadDatatype)
      .then((response: any) => {
        resolve();
      }).catch((error: ShowFileError) => {
        switch (error.type) {
            case ShowFileErrorType.FILE_OPENER_ERROR:
                // Error en ionic devapp -> plugin_not_installed
                this.logger.error('[' + loggerName + '](fileOpener)', error.error);
                this.presentAlert(this.messagesService.getFileOpenerErrorHeader(),
                                            null, this.messagesService.getFileOpenerErrorMessage(error.fileUri));
                break;
            case ShowFileErrorType.DOWNLOAD_ERROR:
                this.logger.error('[' + loggerName + '](downloadError)', error.error);
                this.presentAlert(this.messagesService.getDownloadUrlErrorHeader(),
                                            null, this.messagesService.getDownloadUrlErrorMessage());
                break;
            default:
                // SAVE ERROR or other added later error type
                this.logger.error('[' + loggerName + '](' + error.type + ')', error.error);
                this.presentAlert(this.messagesService.getFileSaveErrorHeader(),
                                            null, this.messagesService.getFileSaveErrorMessage());
                break;
        }
        reject();
      });
    });
  }

  public completeDownloadAction(downloadFileName: string, downloadDatatype: string,
                                downloadBase64: string, loggerName: string): Promise<void> {
    if (this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        return this.completeDownloadActionOnMobile(downloadFileName, downloadDatatype, downloadBase64, loggerName);
    } else {
        return new Promise((resolve: () => void, reject: () => void) => {
          this.downloadFileOnBrowser(downloadFileName, downloadDatatype, downloadBase64);
          resolve();
        });
    }
  }

  private downloadFromUrlOnBrowser(url: string, filename: string) {
    fileSaveAs(url, filename);
  }

  private saveAndOpenUrl(url: string, filename: string): Promise<any> {
    if (!this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) { throw new Error('Expected mobile device but was not found.'); }
    return new Promise<any>((resolve: (toResolve: any) => void, reject: (toReject: ShowFileError) => void) => {
      const writeDirectory = this.platform.is(Constants.IOS_PLARFORM) ?
      this.fileService.dataDirectory : this.fileService.externalDataDirectory;
      const fileUri = writeDirectory + filename;
      let headers = new HttpHeaders();
      headers = Constants.ADD_HEADERS_FOR_BLOB_FILE(headers);
      this.httpClient.get(url, { headers, responseType: Constants.RESPONSE_TYPE_BLOB }).subscribe((blob: Blob) => {
        this.fileService.writeFile(writeDirectory, filename, blob,
          {replace: true}).then((file: any) => {
            /* Example of file response: {
            "isFile": true,
            "isDirectory": false,
            "name": "informe.txt",
            "fullPath": "/informe.txt",
            "filesystem": "<FileSystem: files-external>",
            "nativeURL": "file:///storage/emulated/0/Android/data/io.ionic.devapp/files/informe.txt"
            } */
            this.fileOpener.open(fileUri, blob.type).then((response: any) => {
              resolve(response);
            }).catch((error: Error) => {
              reject(new ShowFileError(error, ShowFileErrorType.FILE_OPENER_ERROR, fileUri));
            });
        }).catch((error: Error) => {
          reject(new ShowFileError(error, ShowFileErrorType.SAVE_ERROR));
        });
      }, (error: Error) => {
        reject(new ShowFileError(error, ShowFileErrorType.DOWNLOAD_ERROR));
      });
    });
  }

  private completeDownloadFromUrlActionOnMobile(url: string, filename: string, loggerName: string): Promise<void> {
    return new Promise((resolve: () => void, reject: () => void) => {
      this.saveAndOpenUrl(url, filename)
      .then((response: any) => {
        resolve();
      }).catch((error: ShowFileError) => {
        switch (error.type) {
            case ShowFileErrorType.FILE_OPENER_ERROR:
                // Error en ionic devapp -> plugin_not_installed
                this.logger.error('[' + loggerName + '](fileOpener)', error.error);
                this.presentAlert(this.messagesService.getFileOpenerErrorHeader(),
                                            null, this.messagesService.getFileOpenerErrorMessage(error.fileUri));
                break;
            case ShowFileErrorType.DOWNLOAD_ERROR:
                this.logger.error('[' + loggerName + '](downloadError)', error.error);
                this.presentAlert(this.messagesService.getDownloadUrlErrorHeader(),
                                            null, this.messagesService.getDownloadUrlErrorMessage());
                break;
            default:
                // SAVE ERROR or other added later error type
                this.logger.error('[' + loggerName + '](' + error.type + ')', error.error);
                this.presentAlert(this.messagesService.getFileSaveErrorHeader(),
                                            null, this.messagesService.getFileSaveErrorMessage());
                break;
        }
        reject();
      });
    });
  }

  public downloadFromURLAction(url: string, filename: string, loggerName: string): Promise<void> {
    if (this.platform.is(Constants.NATIVE_MOBILE_PLATFORM)) {
        return this.completeDownloadFromUrlActionOnMobile(url, filename, loggerName);
    } else {
        return new Promise((resolve: () => void, reject: () => void) => {
          this.downloadFromUrlOnBrowser(url, filename);
          resolve();
        });
    }
  }


  public showErrorFromServiceErrorOnDownload(error: QuproServiceError, loggerName: string) {
    let loggerMessage = '[' + loggerName + '](QuproError) ' +
                          'Error inexperado al contactar con el servidor.';
    let alertMessage = this.messagesService.getDownloadDocumentQuproErrorMessage();
    switch (error.type) {
        case QuproServiceErrorType.QUPRO_ERROR:
            loggerMessage = '[' + loggerName + '](QuproError) Error de qupro.';
            alertMessage = error.message;
            break;
        case QuproServiceErrorType.CONNECTION_PROBLEM:
            loggerMessage = '[' + loggerName + '](QuproError) Error de conexion.';
            alertMessage = this.messagesService.getConnectionProblemMessage();
            break;
        default:
            break;
    }
    this.logger.error(loggerMessage, error);
    this.presentAlert(this.messagesService.getDownloadDocumentQuproErrorHeader(), null,
                                alertMessage);
  }


  public onLoginRedirect() {
    this.fireLoginRedirectEvent();
    this.router.navigate(['login'], { replaceUrl: true }).finally(() => {
      this.messagesService.onInitTranslateService().subscribe(
        () => {
          this.presentLoginRedirecAlert();
        },
        () => {
          this.presentAlert('FATAL ERROR',
                            'Unexpected error',
                            'Error when loading translate service please contact with ' + environment.contactAddress + '.');
        }
      );
    });
  }

  public subscribeToLoginRedirectEvent( methodToSubscribe: () => void ): Subscription  {
    return UiService.LOGIN_REDIRECT_EVENT.subscribe( methodToSubscribe );
  }

  public fireLoginRedirectEvent() {
    UiService.LOGIN_REDIRECT_EVENT.next();
  }

  public subscribeCreateIssueEvent( methodToSubscribe: (issues: IssueOwner[]) => void ): Subscription {
    return UiService.ISSUE_CREATE_EVENT.subscribe( methodToSubscribe );
  }

  public fireCreateIssuetEvent(issues: IssueOwner[]) {
    UiService.ISSUE_CREATE_EVENT.next(issues);
  }

  public subscribeUpdateIssuesEvent( methodToSubscribe: (issues: IssueOwner[]) => void ): Subscription  {
    return UiService.ISSUE_UPDATE_EVENT.subscribe( methodToSubscribe );
  }

  public fireUpdateIssuestEvent(issues: IssueOwner[]) {
    UiService.ISSUE_UPDATE_EVENT.next(issues);
  }

}
