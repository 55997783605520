import { Component, Input, OnInit } from '@angular/core';
import { AdministrativeQueryFollowup } from '../../../models/administrative-query-followup';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { RestHmacUrlResponse } from '../../../models/rest-responses';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss'],
})
export class FollowupComponent implements OnInit {
  listaEstiloPermitidos: string[] = [
    'consulta',
    'respuesta'
  ]

  @Input() followup: AdministrativeQueryFollowup;
  isMobile: boolean = false;
  isDownloadingFile = false;


  constructor(private consultasAdministrativasService: AdministrativeQueriesService,) { }

  ngOnInit() {
    this.isMobile = this.isVistaMobile();
  }

  public getStyle(followup: AdministrativeQueryFollowup): string {
    if(this.listaEstiloPermitidos.includes(followup.followup_type)) {
      return ''+ followup.followup_type;
    } else {
      return 'default';
    }
  }

  public downloadFile() {
    if (this.followup.stored_file_uuid.length <= 0) {
      return
    }
    this.isDownloadingFile = true;
    this.consultasAdministrativasService.getHmacUrl(this.followup.stored_file_uuid).subscribe((restHmacUrlResponse: RestHmacUrlResponse) => {
      // console.log('restHmacUrlResponse: ', restHmacUrlResponse);
      window.open(restHmacUrlResponse.download_url, '_self');
      this.isDownloadingFile = false;
    });
  }

  private isVistaMobile(): boolean {
    let toReturn: boolean = false;
    const modoVista = localStorage.getItem('modoVista');
    if (modoVista === 'mobile') {
      toReturn = true;
    }
    return toReturn;
  }

}
