import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { AdministrativeQueryFollowup } from '../../../models/administrative-query-followup';
import { CreateAdministrativeQueryModalComponent } from '../create-administrative-query-modal/create-administrative-query-modal.component';
import { InputAdministrativeQuery } from '../../../models/input-administrative-query';
import { FollowupsModalComponent } from '../followups-modal/followups-modal.component';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { UiService } from '../../../services/ui.service';
import { RestAdministrativeQueryFollowupsResponse } from '../../../models/rest-responses';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { OwnerExtendedData } from '../../../services/dto/owner.extended.data';

@Component({
  selector: 'app-consultas-administrativas-mobile',
  templateUrl: './consultas-administrativas-mobile.component.html',
  styleUrls: ['./consultas-administrativas-mobile.component.scss'],
})
export class ConsultasAdministrativasMobileComponent implements OnInit, OnDestroy {

  @Input() administrativeQueries: AdministrativeQuery[];
  isMobile: boolean = true;
  ownerManageAdministrativeQueries: boolean = false;
  
  administrativeQuery: AdministrativeQuery;
  hasFollowups: boolean = false;
  administrativeQueryFollowups: AdministrativeQueryFollowup[] = [];

  private selectedAdministrativeQuerySubscription: Subscription;
  private createdAdministrativeQueryFollowupSubscription: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private uiService: UiService, 
    private administrativeQueriesService: AdministrativeQueriesService,
    private portalPropietarioService: PortalPropietarioService
    ) { }

  ngOnInit() {
    const isVistaMobile:boolean = this.isVistaMobile();
    this.portalPropietarioService.getOwnerExtendedDataCached().subscribe(
      (ownerExtendedData: OwnerExtendedData) => {
        if (ownerExtendedData.owner.owner_administrative_queries_allowed_actions) {
          this.ownerManageAdministrativeQueries = ownerExtendedData.owner.owner_administrative_queries_allowed_actions.includes('owner_view_administrative_queries');
        }
      }
    );
    this.selectedAdministrativeQuerySubscription = this.administrativeQueriesService.selectedAdministrativeQueryEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.administrativeQuery = administrativeQuery;
      this.administrativeQueryFollowups = [];
      this.administrativeQueriesService.getFollowpsByAdministrativeQueryId(this.administrativeQuery.id).subscribe((receivedFollowups: RestAdministrativeQueryFollowupsResponse) => {
        this.administrativeQueryFollowups = receivedFollowups.administrativeQueryFollowups;
        if (this.administrativeQueryFollowups && this.administrativeQueryFollowups.length !== 0) {
          this.hasFollowups = true;
          this.administrativeQueriesService.goToBottomEvent.emit(true);
        } else {
          this.hasFollowups = false;
        }
        if (isVistaMobile && this.hasFollowups) {
          this.openFollowupsModal(isVistaMobile)
        }
      }, error => {
        console.log(error['status']);
        if (error['status'] === 500) {
            this.uiService.onLoginRedirect();
        }else if (error['status'] === 401) {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        } else {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
        }
      });  
      
    });
    this.createdAdministrativeQueryFollowupSubscription = this.administrativeQueriesService.createdAdministrativeQueryFollowupEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.setHasFollowups(administrativeQuery);
      this.administrativeQueriesService.goToBottomEvent.emit(true);
    });
  }

  async openFollowupsModal(isVistaMobile?: boolean) {
    const modal = await this.modalCtrl.create({
      component: FollowupsModalComponent,
      componentProps: {
        administrativeQueryFollowups: this.administrativeQueryFollowups,
        administrativeQuery: this.administrativeQuery,
        isVistaMobile: isVistaMobile
      }
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
  }

  async openAdministrativeQueryModal() {
    const modal = await this.modalCtrl.create({
      component: CreateAdministrativeQueryModalComponent,
      
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    // console.log('data recibida: ', data);
    // console.log('inputAdministrativeQueryFollowup: ', data.data);
    let inputAdministrativeQuery: InputAdministrativeQuery = new InputAdministrativeQuery();
    if (data !== undefined) {
      inputAdministrativeQuery = data.data;
      
      if (data.role === 'create') {
        this.administrativeQueriesService.createAdministrativeQueryAndFirstFollowup(inputAdministrativeQuery).subscribe((resp) => {
          this.administrativeQueriesService.createdAdministrativeQueryEvent.emit(true);
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.selectedAdministrativeQuerySubscription) {
      this.selectedAdministrativeQuerySubscription.unsubscribe();
    }
    if (this.createdAdministrativeQueryFollowupSubscription) {
      this.createdAdministrativeQueryFollowupSubscription.unsubscribe();
    }
  }

  private isVistaMobile(): boolean {
    let toReturn: boolean = false;
    const modoVista = localStorage.getItem('modoVista');
    if (modoVista === 'mobile') {
      toReturn = true;
      this.isMobile = true;
    }
    return toReturn;
  }

  private setHasFollowups(administrativeQuery: AdministrativeQuery) {
    this.administrativeQueriesService.getFollowpsByAdministrativeQueryId(administrativeQuery.id).subscribe((receivedFollowups: RestAdministrativeQueryFollowupsResponse) => {
      this.administrativeQueryFollowups = receivedFollowups.administrativeQueryFollowups;
      if (this.administrativeQueryFollowups && this.administrativeQueryFollowups.length !== 0) {
        this.hasFollowups = true;
      } else {
        this.hasFollowups = false;
      }
    }, error => {
      console.log(error['status']);
      if (error['status'] === 500) {
          this.uiService.onLoginRedirect();
      }else if (error['status'] === 401) {
        this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
      } else {
        this.uiService.showErrorFromServiceErrorOnDownload(error, 'ConsultasAdministrativasDesktopComponent');
      }
    });
      
  }
}
