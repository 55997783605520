import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class MessagesService {

  constructor(
    public translateService: TranslateService,
  ) { }

  // Translate methods
  public onInitTranslateService(): Observable<any> {
    return this.translateService.get('language');
  }

  public getLabelFromPhaseKey(phaseKey: string): string {
    let toReturn = this.translateService.instant('general.phases.undefined');
    if (phaseKey) {
      const phaseKeyLowerCase = phaseKey.toLowerCase();
      if (phaseKeyLowerCase === 'postsale' || phaseKeyLowerCase === 'presale' || phaseKeyLowerCase === 'review') {
        toReturn = this.translateService.instant('general.phases.' + phaseKeyLowerCase);
      }
    }
    return toReturn;
  }

  public getLoginErrorOnGuard(): string {
    return this.translateService.instant('guards.login.loginError');
  }

  public getLoginErrorAfterChangePasswordEmptyUsername(): string {
    return this.translateService.instant('components.changePassword.code.loginError');
  }

  public getCanNotUpdateIssueError(): string {
    return this.translateService.instant('general.code.canNotUpdateIssue');
  }

  public getCanNotGetIssueError(): string {
    return this.translateService.instant('general.code.canNotGetUpdatedIssue', {contactAddress: environment.contactAddress});
  }

  public getOkButtonLabel(): string {
    return this.translateService.instant('general.code.ok');
  }

  public getCancelButtonLabel(): string {
    return this.translateService.instant('general.code.cancel');
  }

  public getErrorHeaderLabel(): string {
    return this.translateService.instant('general.code.error');
  }

  public getNotConformSubHeaderLabel(): string {
    return this.translateService.instant('components.issueCard.code.notConformSubHeader');
  }

  public getNotConformHeaderLabel(): string {
    return this.translateService.instant('components.issueCard.code.notConformHeader');
  }

  public getNotConformTextInputPlaceHolder(): string {
    return this.translateService.instant('components.issueCard.code.notConformInputPlaceHolder');
  }

  public getSuccessNotConformOwnerMessage(): string {
    return this.translateService.instant('components.issueCard.code.notConformSentMessage');
  }

  public getSuccessConformOwnerMessage(): string {
    return this.translateService.instant('components.issueCard.code.conformSentMessage');
  }

  public getOwnerConformInfoText(issueId: number): any {
    return this.translateService.instant('components.issueCard.code.ownerConformInfoText', {issueId});
  }

  public canNotLoginAfterChangePasswordMessage(): string {
    return this.translateService.instant('components.changePassword.code.notLogin', {contactAddress: environment.contactAddress});
  }

  public canNotChangePasswordMessage(): string {
    return this.translateService.instant('components.changePassword.code.errorChangeMessage');
  }

  public getErrorChangePasswordHeader(): string {
    return this.translateService.instant('components.changePassword.code.errorTitle');
  }

  public getSuccessChangePassword(): string {
    return this.translateService.instant('components.changePassword.code.successChange');
  }

  public getConnectionProblemMessage(): string {
    return this.translateService.instant('general.code.connectionProblemMessage', { contactAddress: environment.contactAddress});
  }

  public getUnexpectedErrorMessageWithEmailContact(): string {
    return this.translateService.instant('general.code.unexpectedErrorMessageWithEmailContact',
                                         { contactAddress: environment.contactAddress});
  }

  public getLoginRedirectAlertHeader(): string {
    return this.translateService.instant('general.code.loginRedirectAlertTitle');
  }

  public getLoginRedirectAlertSubHeader(): string {
    return this.translateService.instant('general.code.loginRedirectAlertSubTitle');
  }

  public getLoginRedirectAlertMessage(): string {
    return this.translateService.instant('general.code.loginRedirectAlertMessage');
  }

  public getFilterErrorHeader(): string {
    return this.translateService.instant('components.filterIssues.code.errorHeader');
  }

  public getFilterErrorSubHeader(): string {
    return this.translateService.instant('components.filterIssues.code.errorSubHeader');
  }

  public getFilterErrorMessage(): string {
    return this.translateService.instant('components.filterIssues.code.errorMessage');
  }

  public getCreateIssueErrorTitle(): string {
    return this.translateService.instant('newIssue.code.errorTitle');
  }

  public getCreateIssueSuccessMessage(id: number): string {
    return this.translateService.instant('newIssue.code.createIssueSuccesfully', { id });
  }

  public getErrorNewIssueMultipleFilesMessage(): string {
    return this.translateService.instant('newIssue.code.errorMessageMultipleFiles');
  }

  public getErrorNewIssueMultipleFilesTitle(): string {
    return this.translateService.instant('newIssue.code.errorTitleMultipleFiles');
  }

  public getUnexpectedErrorMessage(): string {
    return this.translateService.instant('general.code.unexpectedErrorMessage');
  }

  public getNewIssueExceptionTitle(): string {
    return this.translateService.instant('newIssue.code.createUnexpectedErrorTitle');
  }

  public getErrorNewIssueInvalidForm(): string {
    return this.translateService.instant('newIssue.code.invalidForm');
  }
  public getErrorTitleCanNotGetCameraPhoto(): string {
    return this.translateService.instant('newIssue.code.cameraErrorTitle');
  }

  public getEmailValidationMessageOnResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.emailValidation');
  }

  public getErrorMessageResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorMessage',
                                         { contactAddress: environment.contactAddress});
  }

  public getErrorSubHeaderResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorSubHeader');
  }

  public getErrorHeaderResetPassword(): string {
    return this.translateService.instant('components.resetPassword.code.errorHeader');
  }

  public getMoreActionsActionSheetHeader(): string {
    return this.translateService.instant('components.moreActions.actionsTitle');
  }

  public getMoreActionsIssuesDownloadPdfText(): string {
    return this.translateService.instant('components.moreActions.actions.downloadIssuesPdf');
  }

  public getMoreActionsIssuesCloseText(): string {
    return this.translateService.instant('general.generic.close');
  }

  public getLoadingActionMessage(): string {
    return this.translateService.instant('general.generic.loading');
  }

  public getMoreActionsIssuesDownloadPdfFilenameStart(): string {
    return this.translateService.instant('components.moreActions.filenameStart');
  }

  public getIssuesDownloadFilename(suffix: string, extension: string): string {
    return this.getDownloadFilename(this.getMoreActionsIssuesDownloadPdfFilenameStart(), suffix, extension);
  }

  public getUserManualDownloadFilenameStart(): string {
    return this.translateService.instant('configuration.userManualFilenameStart');
  }

  public getUserManualDownloadFilename(suffix: string, extension: string): string {
    return this.getDownloadFilename(this.getUserManualDownloadFilenameStart(), suffix, extension);
  }

  private getDownloadFilename(filename: string, suffix: string, extension: string): string {
    let toReturn: string = this.translateService.instant('general.generic.downloadFilename', {
      filename,
      suffix,
      extension
    });
    if (toReturn === 'general.generic.downloadFilename') {
      toReturn = 'untranslateFilename.pdf';
    }
    return toReturn;
  }

  public getDatetimeLocaleString(): string {
    const currentLang: string = this.translateService.currentLang;
    const now: Date = new Date();
    return now.toLocaleString(currentLang).replace(/([^a-z0-9]+)/gi, '_').replace(/_+/g, '_');
  }

  public getFileOpenerErrorHeader(): string {
    return this.translateService.instant('general.code.fileOpenerErrorHeader');
  }

  public getFileOpenerErrorMessage(url: string): string {
    return this.translateService.instant('general.code.fileOpenerErrorMessage', {url});
  }

  public getFileSaveErrorHeader(): string {
    return this.translateService.instant('general.code.fileSaveErrorHeader');
  }

  public getFileSaveErrorMessage(): string {
    return this.translateService.instant('general.code.fileSaveErrorMessage');
  }

  public getDownloadUrlErrorHeader(): string {
    return this.translateService.instant('general.code.downloadUrlErrorHeader');
  }

  public getDownloadUrlErrorMessage(): string {
    return this.translateService.instant('general.code.downloadUrlErrorMessage');
  }

  public getDownloadDocumentQuproErrorHeader(): string {
    return this.translateService.instant('general.code.downloadDocumentQuproErrorHeader');
  }

  public getDownloadDocumentQuproErrorMessage(): string {
    return this.translateService.instant('general.code.downloadDocumentQuproErrorMessage');
  }

  public getUnexpectedErrorTitle(): string {
    return this.translateService.instant('general.code.unexpectedErrorTitle');
  }

  public getCanNotGetImageErrorMessage(): string {
    return this.translateService.instant('newIssue.code.imageError');
  }

  public getUnexpectedChangeLanguageErrorMessage(): string {
    return this.translateService.instant('configuration.code.unexpectedErrorChangeLanguage');
  }

  public getLoginReportEmailSubject() {
    return this.translateService.instant('login.code.reportEmailSubject');
  }

  public getMonthPickerFormat(): string {
    return this.translateService.instant('components.ownerIssuesCalendar.code.monthPickerFormat');
  }

  public getWeekDays(): string {
    return this.translateService.instant('components.ownerIssuesCalendar.code.weekdays');
  }

  public getResetPasswordPageErrorHeader(): string {
    return this.translateService.instant('resetPassword.code.errorTitle');
  }

  public getResetPasswordPageErrorNoToken(): string {
    return this.translateService.instant('resetPassword.code.errorNoToken');
  }

  public getResetPasswordPageUnexpectedServerErrorHeader(): string {
    return this.translateService.instant('resetPassword.code.unexpectedServerError');
  }

}
