import { Component, OnInit, Input, EventEmitter, Inject, ViewChild, 
         AfterViewInit, OnDestroy } from '@angular/core';
import { IssuesFilterOwner, MastersService, UserService,
         HousesService, BaseUser, QuproList, Job, House,
         Localization, QuproServiceError,
         Phases, StatesGroups, QuproServiceErrorType } from '@arpada/arp-lib-common-qupro';
import { ModalController } from '@ionic/angular';
import { ActionsSharedData } from '../actions.shared.data';
import { UiService } from '../../../../services/ui.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessagesService } from '../../../../services/messages.service';
import { PortalPropietarioService } from '../../../../services/portal-propietario.service';
import { ActionSheetOptions } from '@ionic/core';

class ComponentsValues {
  postsaleIsChecked: boolean = null;
  presaleIsChecked: boolean;
  pendingProcessingIsChecked: boolean;
  processedNoDateIsChecked: boolean;
  processedDateIsChecked: boolean;
  pendingMaterialIsChecked: boolean;
  repairedClosedIsChecked: boolean;
  notApplicableIsChecked: boolean;
  localizationSelectId: number;
  jobSelectId: number;
  createDateFrom: string;
  createDateTo: string;
  appointmentDateFrom: string;
  appointmentDateTo: string;
  idSelected: number;
}

@Component({
  selector: 'app-filter-issues',
  templateUrl: './filter-issues.component.html',
  styleUrls: ['./filter-issues.component.scss'],
})
export class FilterIssuesComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() filter: IssuesFilterOwner;
  @Input() sharedData: ActionsSharedData;
  @Input() filterChange = new EventEmitter();

  private loginRedirectSubscription: Subscription;
  componentsValues: ComponentsValues = null;
  jobsArray: Job[] = [];
  localizationsArray: Localization[] = [];
  @ViewChild('filterForm', { static : true }) filterForm: NgForm;
  detectChanges = false;
  quproErrorAlertPromise: Promise<HTMLIonAlertElement>;
  readonly customSelectOptions: ActionSheetOptions = { cssClass: 'customSelect', buttons: undefined };
  isMantenimientoCsite: boolean;

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    @Inject(MastersService) private mastersService: MastersService,
    @Inject(UserService) private userService: UserService,
    @Inject(HousesService) private housesService: HousesService,
    @Inject(UiService) private uiService: UiService,
    private messages: MessagesService,
    private portalPropietarioService: PortalPropietarioService,
  ) { }

  ngOnInit() {
    this.isMantenimientoCsite = this.sharedData.isMantenimientoCsite;
    this.componentsValues = {
      postsaleIsChecked: this.filter && this.filter.containsPhaseKey(Phases.POSTSALE),
      presaleIsChecked: this.filter && this.filter.containsPhaseKey(Phases.PRESALE),
      pendingProcessingIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PENDING_PROCESSING),
      processedNoDateIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PROCESSED_NO_DATE),
      processedDateIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PROCESSED_DATE),
      pendingMaterialIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.PENDING_MATERIAL),
      repairedClosedIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.REPAIRED_CLOSE),
      notApplicableIsChecked: this.filter && this.filter.containsStateGroupKey(StatesGroups.NOT_APPLICABLE),
      localizationSelectId: this.filter ? this.filter.location_id : null,
      jobSelectId: this.filter ? this.filter.job_id : null,
      createDateFrom: this.filter ? this.getStringOfTimestamp(this.filter.create_date_from) : null,
      createDateTo: this.filter ? this.getStringOfTimestamp(this.filter.create_date_to) : null,
      appointmentDateFrom: this.filter ? this.getStringOfTimestamp(this.filter.appointment_date_from) : null,
      appointmentDateTo: this.filter ? this.getStringOfTimestamp(this.filter.appointment_date_to) : null,
      idSelected: this.filter ? this.filter.id : null
    };
    this.userService.getLoggedUserDataCached().subscribe((userData: BaseUser) => {
      const ownerId = userData.owner_id;
      this.housesService.getOwnerHouseCached(ownerId).subscribe((house: House) => {
        const commonArea = house.common_area;
        const csiteId = house.csite_id;
        this.mastersService.getCachedLocalizations(csiteId, commonArea).subscribe((localizationsList: QuproList<Localization>) => {
          this.localizationsArray = localizationsList.elements;
        }, this.onQuproError);
      }, this.onQuproError);
    }, this.onQuproError);
    this.mastersService.getCachedJobs().subscribe(
      (jobsList: QuproList<Job>) => {
        this.jobsArray = jobsList.elements;
      },
      this.onQuproError
    );
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
  }

  ngAfterViewInit(): void {
    /* Use of window.setTimeout because the fromControl isnt init.
     * See: https://stackoverflow.com/questions/50286424/error-there-are-no-form-controls-registered-with-this-group-yet-if-youre-usin
     */
    setTimeout(() => {
      this.detectChanges = true;
      this.filterForm.valueChanges.subscribe(this.onChangeComponent);
    });
  }

  ngOnDestroy() {
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  onChangeComponent = () => {
    if (this.detectChanges) {
      setTimeout(() => {
        this.updateFiltro();
      }, 50); // Time to apply filters, after change this.filter
    }
  }

  isTotalCountDefined() {
    return this.sharedData && this.sharedData.totalCount
           && this.sharedData.totalCount !== '' && parseInt(this.sharedData.totalCount, 10) >= 0;
  }

  updateFiltro() {
    if ( !this.componentsValues ) { return; }
    this.updatePhases();
    this.updateStatesGroups();
    this.updateFeatures();
    this.updateDates();
    this.updateId();
    this.filterChange.emit();
  }

  clearDateFilter(dateFilterPropertyKey: string) {
    if (this.componentsValues && this.componentsValues[dateFilterPropertyKey]) {
      this.componentsValues[dateFilterPropertyKey] = null;
      this.updateDates();
    }
  }

  private getStringOfTimestamp(timestamp: number): string {
    let toReturn: string = null;
    if (timestamp) {
      toReturn = new Date(timestamp).toISOString();
      // formatDate( timestamp, 'YYYY-MM-DDThh:mm:ss.sTZD', 'es', '+0100');
    }
    return toReturn;
  }

  private onQuproError = (error: QuproServiceError) => {
    if ((!this.quproErrorAlertPromise) && error.type !== QuproServiceErrorType.UNATHORIZED) {
      this.quproErrorAlertPromise = this.uiService.presentAlert(this.messages.getFilterErrorHeader(),
      this.messages.getFilterErrorSubHeader(),
      this.messages.getFilterErrorMessage());
      this.quproErrorAlertPromise.then((alert) => {
        alert.onDidDismiss().finally(() => {
          this.quproErrorAlertPromise = null;
        });
      }).catch(() => {
        this.quproErrorAlertPromise = null;
      });
    }
    this.portalPropietarioService.logger.error('[FilterIssues.onQuproError] ' +
                                               'Error obteniendo los datos necesarios para aplicar los filtros.', error);
  }

  public dismiss() {
    this.detectChanges = false;
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  private updateId() {
    this.filter.id = this.componentsValues.idSelected;
  }

  private updatePhases() {
    const phaseArray: string[] = [];
    if (this.componentsValues.postsaleIsChecked) {
      phaseArray.push(Phases.POSTSALE);
    }
    if (this.componentsValues.presaleIsChecked) {
      phaseArray.push(Phases.PRESALE);
    }
    this.filter.setPhaseKeys(phaseArray);
  }

  private updateStatesGroups() {
    const stateGroups: string[] = [];
    if (this.componentsValues.pendingProcessingIsChecked) {
      stateGroups.push(StatesGroups.PENDING_PROCESSING);
    }
    if (this.componentsValues.processedNoDateIsChecked) {
      stateGroups.push(StatesGroups.PROCESSED_NO_DATE);
    }
    if (this.componentsValues.processedDateIsChecked) {
      stateGroups.push(StatesGroups.PROCESSED_DATE);
    }
    if (this.componentsValues.pendingMaterialIsChecked) {
      stateGroups.push(StatesGroups.PENDING_MATERIAL);
    }
    if (this.componentsValues.repairedClosedIsChecked) {
      stateGroups.push(StatesGroups.REPAIRED_CLOSE);
    }
    if (this.componentsValues.notApplicableIsChecked) {
      stateGroups.push(StatesGroups.NOT_APPLICABLE);
    }
    this.filter.setStateGroupKeys(stateGroups);
  }

  private updateFeatures() {
    this.filter.location_id = this.componentsValues.localizationSelectId;
    this.filter.job_id = this.componentsValues.jobSelectId;
  }

  private updateDates() {
    if (this.componentsValues.createDateFrom) {
      const createDateFrom: Date = new Date(this.componentsValues.createDateFrom);
      createDateFrom.setHours(0, 0, 0, 0);
      this.filter.create_date_from = createDateFrom.getTime();
    } else {
      this.filter.create_date_from = null;
    }
    if (this.componentsValues.createDateTo) {
      const createDateTo: Date = new Date(this.componentsValues.createDateTo);
      createDateTo.setHours(23, 59, 59, 999);
      this.filter.create_date_to = createDateTo.getTime();
    } else {
      this.filter.create_date_to = null;
    }
    if (this.componentsValues.appointmentDateFrom) {
      const appointmentDateFrom: Date = new Date(this.componentsValues.appointmentDateFrom);
      appointmentDateFrom.setHours(0, 0, 0, 0);
      this.filter.appointment_date_from = appointmentDateFrom.getTime();
    } else {
      this.filter.appointment_date_from = null;
    }
    if (this.componentsValues.appointmentDateTo) {
      const appointmentDateTo: Date = new Date(this.componentsValues.appointmentDateTo);
      appointmentDateTo.setHours(23, 59, 59, 999);
      this.filter.appointment_date_to = appointmentDateTo.getTime();
    } else {
      this.filter.appointment_date_to = null;
    }
  }
}
