export enum ViewChangeType {
    COMPACT_LIST_VIEW = 'CompactListView',
    CARD_LIST_VIEW = 'CardListView',
}
export class ViewChangeEvent {

    private viewType: ViewChangeType;

    constructor(viewType: ViewChangeType) {
        this.viewType  = viewType;
    }

    public getViewType() {
        return this.viewType;
    }
}
