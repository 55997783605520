import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertController, IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { InputAdministrativeQueryFollowup } from '../../../models/input-administrative-query-followup';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { MessagesService } from '../../../services/messages.service';
import { UiService } from '../../../services/ui.service';
import { InputFollowupFileToUpload } from '../../../models/input-followup-file-to-upload';

@Component({
  selector: 'app-footer-followups',
  templateUrl: './footer-followups.component.html',
  styleUrls: ['./footer-followups.component.scss'],
})
export class FooterFollowupsComponent implements OnInit, OnDestroy {

  @Input() administrativeQuery: AdministrativeQuery;
  newFollowupText: string = '';
  inputFollowupFileToUpload: InputFollowupFileToUpload = null;

  administrativeQueryIsClosed: boolean = false;
  private selectedAdministrativeQuerySubscription: Subscription;

  readingPhoto = false;
  base64File: string = null;

  fileSelected = false;

  disabledSendButton = true;
  disabledAttachButton = false;

  constructor(
    private consultasAdministrativasService: AdministrativeQueriesService,
    private portalPropietarioService: PortalPropietarioService,
    private messagesService: MessagesService,
    private uiService: UiService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.isAdministrativeQueryClosed(this.administrativeQuery); // para cuando vengo del modal
    this.selectedAdministrativeQuerySubscription = this.consultasAdministrativasService.selectedAdministrativeQueryEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.administrativeQuery = administrativeQuery;
      this.isAdministrativeQueryClosed(this.administrativeQuery);
    });
  }

  detectarCambio(newFollowupText: string) {
    // console.log('detecto cambio: ', newFollowupText);
    if (this.isEmptyText(newFollowupText)) {
      // console.log('no debería poder mandar nada');
      this.disabledSendButton = true;
      return;
    } else if (this.isWhiteSpaceText(newFollowupText)) {
      this.disabledSendButton = true;
      return;
    } else {
      this.disabledSendButton = false;
    }
  }

  enviar() {
    if (this.isEmptyText(this.newFollowupText)) {
      this.newFollowupText = '';
      return;
    }
    if (this.isWhiteSpaceText(this.newFollowupText)) {
      this.newFollowupText = '';
      return;
    }
    this.disabledSendButton = true;
    const inputAdministrativeQueryFollowup: InputAdministrativeQueryFollowup = new InputAdministrativeQueryFollowup();
    inputAdministrativeQueryFollowup.followup_text = this.newFollowupText;
    inputAdministrativeQueryFollowup.administrative_query_id = this.administrativeQuery.id;
    
    if (this.inputFollowupFileToUpload !== undefined) {
      inputAdministrativeQueryFollowup.input_filename_to_upload = this.inputFollowupFileToUpload;
    }
    this.newFollowupText = '';
    this.consultasAdministrativasService.createAdministrativeQueryFollowup(inputAdministrativeQueryFollowup).subscribe((resp) => {
      this.disabledAttachButton = false;
      this.cancelFileUpload();
      this.consultasAdministrativasService.createdAdministrativeQueryFollowupEvent.emit(this.administrativeQuery);
      this.consultasAdministrativasService.goToBottomEvent.emit(true);
    });
  }

  private isAdministrativeQueryClosed(administrativeQuery: AdministrativeQuery) {
    if (administrativeQuery.status === 'closed') {
      this.administrativeQueryIsClosed = true;
    } else {
      this.administrativeQueryIsClosed = false;
    }
  }

  ngOnDestroy(): void {
    if (this.selectedAdministrativeQuerySubscription) {
      this.selectedAdministrativeQuerySubscription.unsubscribe();
    }
  }

  cancelFileUpload() {
    this.fileSelected = false;
    this.disabledSendButton = true;
    this.readingPhoto = false;
    this.disabledAttachButton = false;
    this.base64File = null;
    this.inputFollowupFileToUpload = null;
  }

  ionInputFileChangeEvent(ionInput: IonInput, event: Event): void {
    ionInput.getInputElement().then((input: HTMLInputElement) => {
      if (input && input.files) {
        this.fileSelected = true;
        this.disabledAttachButton = true;
        this.fileChangeEvent(input.files, event);
      } else {
        this.portalPropietarioService.logger.error('[footerFollowup.ionInputFileChangeEvent] No existe el input o el atributo files.');
        this.uiService.presentAlert(this.messagesService.getUnexpectedErrorTitle(), null,
        this.messagesService.getCanNotGetImageErrorMessage());
      }
    }).catch((error: Error) => {
      this.portalPropietarioService.logger.error('[footerFollowup.ionInputFileChangeEvent] No existe el elemento para obtener la imagen.', error);
      this.uiService.presentAlert(this.messagesService.getUnexpectedErrorTitle(), null,
                                  this.messagesService.getCanNotGetImageErrorMessage());
    });
  }

  fileChangeEvent(files: FileList, event: Event): void {
    this.inputFollowupFileToUpload = new InputFollowupFileToUpload();
    this.readingPhoto = false;
    this.disabledSendButton = false;
    if (files.length <= 0) {
      this.base64File = null;
    } else if (files.length > 1) {
      this.base64File = null;
      this.showErrorAlert(this.messagesService.getErrorNewIssueMultipleFilesTitle(),
                          this.messagesService.getErrorNewIssueMultipleFilesMessage());
    } else {
      this.readingPhoto = true;
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const srcText: string = fileReader.result.toString();
        this.fileSelected = true;
        this.disabledAttachButton = true;
        this.base64File = srcText;
        const arrayBase64 = this.base64File.split(',');
        this.inputFollowupFileToUpload.base64 = arrayBase64.pop();
        this.inputFollowupFileToUpload.mimetype = files[0].type;
        this.inputFollowupFileToUpload.filename = files[0].name;
        this.inputFollowupFileToUpload.file_extension = this.getFileExtension(files[0].name);
        this.readingPhoto = false;

      };
      fileReader.readAsDataURL(files[0]);
    }
  }

  showErrorAlert(title: string, message: string): void {
    const okLabel: string = this.messagesService.getOkButtonLabel();
    const errorHeader: string = this.messagesService.getErrorHeaderLabel();
    this.alertCtrl.create({
      header: errorHeader,
      subHeader: title,
      message,
      buttons: [{
        text: okLabel,
        cssClass: 'primary'
      }]
    }).then(alert => {
      alert.present();
    });
  }

  private getFileExtension(filename: string): string {
    const stringList = filename.split('.');
    return stringList.pop();
  }

  private isEmptyText(text: string): boolean {
    let toReturn = false;
    if (text.length <= 0) {
      toReturn = true;
    }
    return toReturn;
  }

  private isWhiteSpaceText(text: string): boolean {
    let toReturn = false;
    if (!text.replace(/\s/g, '').length) {
      toReturn = true;
    }
    return toReturn;
  }

}
