import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import {
  IssuesService, IssuesFilterOwner, QuproList, IssueOwner, BaseUser,
  UserService, IssuesOrderOwner, IssueOrderOwnerType
} from '@arpada/arp-lib-common-qupro';
import { IssueViewModel } from '../../../models/issueViewModel';
import { PopulateService } from '../../../services/populate.service';
import { ActionsSharedData } from '../actions-issues-header/actions-issues-header.component';
import { UiService } from '../../../services/ui.service';
import { Observable, Subscription } from 'rxjs';
import { IonInfiniteScroll, IonContent, Platform, IonRefresher } from '@ionic/angular';
import { Constants } from '../../../models/constants';
import { ViewChangeType, ViewChangeEvent } from '../../../models/view.change.event';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { OwnerExtendedData } from '../../../services/dto/owner.extended.data';

@Component({
  selector: 'issues-card-list',
  templateUrl: './issues-card-list.component.html',
  styleUrls: ['./issues-card-list.component.scss'],
})
export class IssuesCardListComponent implements OnInit, OnDestroy {

  @Output() public viewChange = new EventEmitter();

  issues: IssueViewModel[] = [];
  loading = true;
  issueFilter: IssuesFilterOwner = new IssuesFilterOwner();
  issueOrder: IssuesOrderOwner = new IssuesOrderOwner(IssueOrderOwnerType.DEFAULT_ORDER);
  totalCount = '-';
  totalCountNumber = -1;
  sharedData: ActionsSharedData = new ActionsSharedData();
  lastTimestampLoadIssues: number = null;
  private start = 0;
  private limit = 12;
  private startLimit = 12;
  private updateIssueSubscription: Subscription;
  private createIssueSubscription: Subscription;
  @ViewChild('issuesInfiniteScroll', {static: true}) infiniteScroll: IonInfiniteScroll;
  @ViewChild('issuesIonContent', { static: true }) ionContent: IonContent;
  @ViewChild('issuesRefresher', { static: true }) issuesRefresher: IonRefresher;


  constructor(
    private issueService: IssuesService,
    private populateService: PopulateService,
    private userService: UserService,
    private uiService: UiService,
    private platform: Platform,
    private portalPropietarioService: PortalPropietarioService
  ) {
    this.issueFilter = new IssuesFilterOwner();
    this.issueFilter.setPhaseKeys(Constants.OWNER_ALL_PHASES_FILTER);
    this.issueFilter.setStateGroupKeys(Constants.OWNER_ALL_STATES_GROUPS_FILTER);
    if (this.platform.width() >= 1200 && (this.platform.height() >= 1600 && this.platform.height() <= 2500)) {
      this.startLimit = 24; // load more issues for big screens
    } else if (this.platform.width() >= 1200 && this.platform.height() > 2500) {
      this.startLimit = 36; // load more issues for extra-big screens
    }
  }

  ngOnInit() {
    this.setTotalCount(-1);
    const processUserData = (userData: BaseUser) => {
      this.issueFilter.owner_id = userData.owner_id;
      this.loadIssues(this.startLimit);
      this.portalPropietarioService.getOwnerExtendedDataCached().subscribe(
        (ownerExtendedData: OwnerExtendedData) => {
          this.sharedData.isMantenimientoCsite = ownerExtendedData.csite.is_csite_mantenimiento;
        }
      );
    };
    this.userService.getLoggedUserDataCached().subscribe(processUserData);
    this.createIssueSubscription = this.uiService.subscribeCreateIssueEvent(this.onCreateIssues);
    this.updateIssueSubscription = this.uiService.subscribeUpdateIssuesEvent(this.onUpdateIssues);
  }

  ngOnDestroy(): void {
    this.updateIssueSubscription.unsubscribe();
    this.createIssueSubscription.unsubscribe();
  }

  onFilterChange() {
    this.start = 0;
    this.infiniteScroll.disabled = false;
    this.setTotalCount(-1);
    this.loadIssues().subscribe(() => {
      this.ionContent.scrollToTop();
    });
  }

  onOrderChange() {
    this.loadIssues().subscribe(() => {
      this.ionContent.scrollToTop();
    });
  }

  loadIssues(forceLimit?: number): Observable<QuproList<IssueOwner>> {
    const currentLimit = forceLimit ? forceLimit : this.limit;
    this.lastTimestampLoadIssues = new Date().getTime();
    const timestampToUseInTransform = this.lastTimestampLoadIssues;
    const transformIssues = (issuesList: QuproList<IssueOwner>) => {
      if (timestampToUseInTransform === this.lastTimestampLoadIssues) {
        if (issuesList.count <= this.start + currentLimit) {
          this.infiniteScroll.disabled = true;
        }
        this.setTotalCount(issuesList.count);
        if (this.start <= 0) {
          this.issues = [];
        }
        issuesList.elements.forEach((issue: IssueOwner) => {
          this.issues.push(this.populateService.populateToIssueViewModel(issue));
        });
        this.loading = false;
      }
    };
    const toReturn: Observable<QuproList<IssueOwner>> =
      this.issueService.getIssuesOwner(this.issueFilter, this.issueOrder, this.start, currentLimit);
    toReturn.subscribe(transformIssues);
    return toReturn;
  }

  scrollEvent(/*event: CustomEvent*/) {
    this.start = this.start + this.limit;
    this.loadIssues().subscribe((/*issuesQuproList: QuproList<Issue>*/) => {
      this.infiniteScroll.complete();
    });
  }

  refreshEvent(/*event: CustomEvent*/) {
    this.start = 0;
    this.infiniteScroll.disabled = false;
    this.setTotalCount(-1);
    this.loadIssues().subscribe((/*issuesQuproList: QuproList<Issue>*/) => {
      this.ionContent.scrollToTop();
      this.issuesRefresher.complete();
    });
  }

  onCompactListPressed() {
    this.viewChange.emit(new ViewChangeEvent(ViewChangeType.COMPACT_LIST_VIEW));
  }

  private setTotalCount(totalCountNumber: number) {
    this.totalCountNumber = totalCountNumber;
    if (totalCountNumber >= 0) {
      this.totalCount = totalCountNumber + '';
      this.sharedData.totalCount = totalCountNumber + '';
    } else {
      this.totalCount = '-';
      this.sharedData.totalCount = '-';
    }
  }

  private onUpdateIssues = (issues: IssueOwner[]) => {
    if (issues) {
      issues.forEach((issue: IssueOwner) => {
        if (issue) {
          const issueToUpdate: IssueViewModel = this.getIssueOnListWithId(issue.id);
          if (issueToUpdate) {
            this.populateService.updateIssueViewModelWithServiceIssue(issueToUpdate, issue);
          }
        }
      });
    }
  }

  private getIssueOnListWithId(id: number): IssueViewModel {
    let toReturn: IssueViewModel = null;
    if (this.issues && this.issues.length > 0) {
      for (const issue of this.issues) {
        if (issue && issue.id && issue.id === id) {
          toReturn = issue;
          break;
        }
      }
    }
    return toReturn;
  }

  private onCreateIssues = (issues: IssueOwner[]) => {
    if (!this.issues) { this.issues = []; }
    if (issues) {
      issues.reverse().forEach(issue => {
        this.issues.unshift(this.populateService.populateToIssueViewModel(issue));
      });
    }
    this.setTotalCount((this.totalCountNumber + issues.length));
  }
}
