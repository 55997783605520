import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IonList, IonInfiniteScroll, IonContent, ModalController, IonRefresher, AlertController, Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { IssueViewModel } from '../../../models/issueViewModel';
import { ActionsSharedData } from '../actions-issues-header/actions.shared.data';
import { IssuesFilterOwner, IssuesOrderOwner, IssueOrderOwnerType, IssuesService, IssueUpdateDataValidation,
         UserService, QuproList, IssueOwner, BaseUser, QuproServiceErrorType, QuproServiceError } from '@arpada/arp-lib-common-qupro';
import { PopulateService } from '../../../services/populate.service';
import { UiService } from '../../../services/ui.service';
import { IssueDetailedComponent } from '../../issue/issue-detailed/issue-detailed.component';
import { ViewChangeType, ViewChangeEvent } from '../../../models/view.change.event';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { MessagesService } from '../../../services/messages.service';
import { Constants } from '../../../models/constants';
import { OwnerExtendedData } from '../../../services/dto/owner.extended.data';

@Component({
  selector: 'issues-compact-list',
  templateUrl: './issues-compact-list.component.html',
  styleUrls: ['./issues-compact-list.component.scss'],
})
export class IssuesCompactListComponent implements OnInit, OnDestroy {

  @Output() public viewChange = new EventEmitter();

  @ViewChild('lista', {static: false}) lista: IonList;
  issues: IssueViewModel[] = [];
  loading = true;
  issueFilter: IssuesFilterOwner = new IssuesFilterOwner();
  issueOrder: IssuesOrderOwner = new IssuesOrderOwner(IssueOrderOwnerType.DEFAULT_ORDER);
  totalCount = '-';
  totalCountNumber = -1;
  sharedData: ActionsSharedData = new ActionsSharedData();
  lastTimestampLoadIssues: number = null;
  processingRequest = false;
  txtNoConforme: string;
  private start = 0;
  private limit = 10;
  private startLimit = 10;
  private updateIssueSubscription: Subscription;
  private createIssueSubscription: Subscription;
  @ViewChild('issuesInfiniteScroll', {static: true}) infiniteScroll: IonInfiniteScroll;
  @ViewChild('issuesIonContent' , { static: true }) ionContent: IonContent;
  @ViewChild('issuesRefresher', { static: true }) issuesRefresher: IonRefresher;


  constructor(private issueService: IssuesService,
              private populateService: PopulateService,
              private userService: UserService,
              private uiService: UiService,
              private modalController: ModalController,
              private portalPropietarioService: PortalPropietarioService,
              private messagesService: MessagesService,
              private alertCtrl: AlertController,
              private platform: Platform,
              ) {
    this.issueFilter = new IssuesFilterOwner();
    this.issueFilter.setPhaseKeys(Constants.OWNER_ALL_PHASES_FILTER);
    this.issueFilter.setStateGroupKeys(Constants.OWNER_ALL_STATES_GROUPS_FILTER);
    // If we change height of rows or component we need to recalculate this limits.
    if (this.platform.height() >= 950 && this.platform.height() <= 1200) {
      this.startLimit = 15;
    } else if (this.platform.height() > 1200 && this.platform.height() <= 1700) {
      this.startLimit = 20;
    } else if (this.platform.height() > 1700  && this.platform.height() <= 2000) {
      this.startLimit = 25;
    } else if (this.platform.height() > 2000  && this.platform.height() <= 2500) {
      this.startLimit = 30;
    } else if (this.platform.height() > 2500) {
      this.startLimit = 50;
    }
   }

  ngOnInit() {
    this.setTotalCount(-1);
    const processUserData = (userData: BaseUser) => {
      this.issueFilter.owner_id = userData.owner_id;
      this.loadIssues(this.startLimit);
      this.portalPropietarioService.getOwnerExtendedDataCached().subscribe(
        (ownerExtendedData: OwnerExtendedData) => {
          this.sharedData.isMantenimientoCsite = ownerExtendedData.csite.is_csite_mantenimiento;
        }
      );
    };
    this.userService.getLoggedUserDataCached().subscribe(processUserData);
    this.createIssueSubscription = this.uiService.subscribeCreateIssueEvent(this.onCreateIssues);
    this.updateIssueSubscription = this.uiService.subscribeUpdateIssuesEvent(this.onUpdateIssues);
  }

  ngOnDestroy(): void {
    this.updateIssueSubscription.unsubscribe();
    this.createIssueSubscription.unsubscribe();
  }

  onFilterChange() {
    this.start = 0;
    this.infiniteScroll.disabled = false;
    this.setTotalCount(-1);
    this.loadIssues().subscribe(() => {
      this.ionContent.scrollToTop();
    });
  }

  onOrderChange() {
    this.loadIssues().subscribe(() => {
      this.ionContent.scrollToTop();
    });
  }

  loadIssues(startLimit?: number): Observable<QuproList<IssueOwner>> {
    const currentLimit = startLimit ? startLimit : this.limit;
    this.lastTimestampLoadIssues = new Date().getTime();
    const timestampToUseInTransform = this.lastTimestampLoadIssues;
    const transformIssues = (issuesList: QuproList<IssueOwner>) => {
      if (timestampToUseInTransform === this.lastTimestampLoadIssues) {
        if (issuesList.count <= this.start + currentLimit) {
          this.infiniteScroll.disabled = true;
        }
        this.setTotalCount(issuesList.count);
        if (this.start <= 0) {
          this.issues = [];
        }
        issuesList.elements.forEach((issue: IssueOwner) => {
          this.issues.push(this.populateService.populateToIssueViewModel(issue));
        });
        this.loading = false;
      }
    };
    const toReturn: Observable<QuproList<IssueOwner>> =
      this.issueService.getIssuesOwner(this.issueFilter, this.issueOrder, this.start, currentLimit);
    toReturn.subscribe(transformIssues);
    return toReturn;
  }

  refreshEvent(/*event: CustomEvent*/) {
    this.start = 0;
    this.infiniteScroll.disabled = false;
    this.setTotalCount(-1);
    this.loadIssues().subscribe((/*issuesQuproList: QuproList<Issue>*/) => {
      this.ionContent.scrollToTop();
      this.issuesRefresher.complete();
    });
  }

  scrollEvent(/*event: CustomEvent*/) {
    this.start = this.start + this.limit;
    this.loadIssues().subscribe((/*issuesQuproList: QuproList<Issue>*/) => {
      this.infiniteScroll.complete();
    });
  }

  async viewIssueDetail(issue: IssueOwner) {
    const modal = await this.modalController.create({
      component: IssueDetailedComponent,
      componentProps: {
        issue
      }
    });
    return await modal.present();
  }

  async ownerConform(conform: boolean, event: Event, issue: IssueViewModel) {
    event.stopPropagation();
    if (!conform) {
      const okButtonLabel: string = this.messagesService.getOkButtonLabel();
      const cancelButtonLabel: string = this.messagesService.getCancelButtonLabel();
      const notConformHeaderLabel: string = this.messagesService.getNotConformHeaderLabel();
      const notConformSubHeaderLabel: string = this.messagesService.getNotConformSubHeaderLabel();
      const notConformTextInputPlaceHolder: string = this.messagesService.getNotConformTextInputPlaceHolder();
      const input = await this.alertCtrl.create({
        backdropDismiss: false,
        header: notConformHeaderLabel,
        subHeader: notConformSubHeaderLabel,
        inputs: [
          {
            name: 'txtNoConforme',
            type: 'text',
            placeholder: notConformTextInputPlaceHolder,
          }
        ],
        buttons: [
          {
            text: cancelButtonLabel,
            role: 'cancel',
            handler: (blah) => {
            }
          }, {
            text: okButtonLabel,
            handler: (data) => {
              this.txtNoConforme = data.txtNoConforme;
              this.addNoConformComment(this.txtNoConforme, issue);
            }
          }
        ]
      });
      await input.present();
    } else {
      this.conformOk(issue);
    }
    this.lista.closeSlidingItems();
  }

  addNoConformComment(txtNoConforme: string, issue: IssueViewModel) {
    this.portalPropietarioService.updateIssue(issue.id,
      {owner_comment: txtNoConforme, validation: IssueUpdateDataValidation.NO}).subscribe(
      (obj: string) => {
        this.uiService.showSimpleBottomToast(this.messagesService.getSuccessNotConformOwnerMessage(), 2000);
        this.updateIssueData(issue);
      },
      (error: QuproServiceError) => {
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED && error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(error.message, 5000);
        }
      }
    );
  }

  conformOk(issue: IssueViewModel) {
    this.portalPropietarioService.updateIssue(issue.id,
      {validation: IssueUpdateDataValidation.YES}).subscribe(
      (obj: string) => {
        this.uiService.showSimpleBottomToast(this.messagesService.getSuccessConformOwnerMessage(), 2000);
        this.updateIssueData(issue);
      },
      (error: QuproServiceError) => {
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED && error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(error.message, 5000);
        }
      }
    );
  }

  updateIssueData(issue: IssueViewModel) {
    this.portalPropietarioService.getIssue(issue.id).subscribe(
     (issueRecived: IssueOwner) => {
        this.uiService.fireUpdateIssuestEvent([issueRecived]);
        this.processingRequest = false;

     },
     (error: QuproServiceError) => {
      this.processingRequest = false;
      if (error && error.type !== QuproServiceErrorType.UNATHORIZED &&
          error.type !== QuproServiceErrorType.QUPRO_ERROR) {
        this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotGetIssueError(), 5000);
      } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
        this.uiService.showSimpleMiddleToast(error.message, 5000);
      }
     },
    );
  }

  onCardListPressed(event: Event) {
    this.viewChange.emit(new ViewChangeEvent(ViewChangeType.CARD_LIST_VIEW));
  }

  private setTotalCount(totalCountNumber: number) {
    this.totalCountNumber = totalCountNumber;
    if (totalCountNumber >= 0) {
      this.totalCount = totalCountNumber + '';
      this.sharedData.totalCount = totalCountNumber + '';
    } else {
      this.totalCount = '-';
      this.sharedData.totalCount = '-';
    }
  }

  private onUpdateIssues = (issues: IssueOwner[]) => {
    if (issues) {
      issues.forEach((issue: IssueOwner) => {
        if (issue) {
          const issueToUpdate: IssueViewModel = this.getIssueOnListWithId(issue.id);
          if (issueToUpdate) {
            this.populateService.updateIssueViewModelWithServiceIssue(issueToUpdate, issue);
          }
        }
      });
    }
  }

  private getIssueOnListWithId(id: number): IssueViewModel {
    let toReturn: IssueViewModel = null;
    if (this.issues && this.issues.length > 0) {
      for (const issue of this.issues) {
        if (issue && issue.id && issue.id === id) {
          toReturn = issue;
          break;
        }
      }
    }
    return toReturn;
  }

  private onCreateIssues = (issues: IssueOwner[]) => {
    if (!this.issues) { this.issues = []; }
    if (issues) {
      issues.reverse().forEach(issue => {
        this.issues.unshift(this.populateService.populateToIssueViewModel(issue));
      });
    }
    this.setTotalCount((this.totalCountNumber + issues.length));
  }
}
