import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService, HousesService, LoginData, BaseUser,
         House, CsiteService, CsiteBase, IssueUpdateDataOwner, IssuesService,
         IssuesOrderOwner, IssueOrderOwnerType, QuproObservable, QuproList, QuproServiceError,
         LocalStorageQuproLogger, QuproService, DocumentReport, IssueOwner,
         IssuesFilterOwner, AppoimentData, AgreementService, OwnerAgreementUploaded,
       } from '@arpada/arp-lib-common-qupro';
import { MessagesService } from './messages.service';
import { UiService } from './ui.service';
import { OwnerExtendedData } from './dto/owner.extended.data';
import { environment } from 'src/environments/environment';
import { Constants } from '../models/constants';
import { UrlFileDownloadData } from '../models/urlFileDownloadData';
import { PopulateService } from './populate.service';

@Injectable({
  providedIn: 'root'
})
export class PortalPropietarioService {

  private readonly EXPOSED_QUPRO_URL: string;
  private readonly USER_MANUAL_PATH_TEMPLATE: string = 'queries/public/csite/manual/{csite_id}/{filename}';

  constructor(public userService: UserService,
              public houseService: HousesService,
              public uiService: UiService,
              public messagesService: MessagesService,
              public csiteService: CsiteService,
              public issueService: IssuesService,
              public logger: LocalStorageQuproLogger,
              public quproService: QuproService,
              public populateService: PopulateService,
              public agreementService: AgreementService,
              ) {
                let fastapiExposedQuproBaseURL: string = environment.fastapiExposedQuproBaseURL;
                if (!fastapiExposedQuproBaseURL.endsWith('/')) {
                  fastapiExposedQuproBaseURL += '/';
                }
                this.EXPOSED_QUPRO_URL = fastapiExposedQuproBaseURL;
              }

  public loginAfterChangePassword(password: string): Observable<LoginData> {
    const username = this.userService.getLoggedUserName();
    if (username) {
      return this.userService.loginUsuario(username, password);
    } else {
      throw new Error(this.messagesService.getLoginErrorAfterChangePasswordEmptyUsername());
    }
  }

  public changeLocaleOfUser(selectedLang: string): Observable<string> {
    return this.userService.changeLocaleOfUser(selectedLang);
  }

  public getPropietarioLoggedUserDataCached(): Observable<BaseUser> {
    return this.userService.getLoggedUserDataCached();
  }

  public getPropietarioLoggedUserDataNoCache(): Observable<BaseUser> {
    return this.userService.getLoggedUserDataNoCache();
  }

  public getPropietarioHouseCached(propietarioId: number): Observable<House> {
    return this.houseService.getOwnerHouseCached(propietarioId);
  }

  public getObraPisoPropietario(obraId: number): Observable<CsiteBase> {
    return this.csiteService.getOwnerCsiteCached(obraId);
  }

  public updateIssue(id: number, dataToUpdate: IssueUpdateDataOwner): Observable<string> {
    return this.issueService.updateIssue(id, dataToUpdate);
  }

  public getIssue(id: number): Observable<IssueOwner> {
    const filter: IssuesFilterOwner = new IssuesFilterOwner();
    filter.id = id;
    const order: IssuesOrderOwner = new IssuesOrderOwner(IssueOrderOwnerType.DEFAULT_ORDER);
    const toReturn: QuproObservable<IssueOwner> = new QuproObservable<IssueOwner>();
    this.issueService.getIssuesOwner(filter, order, 0, 1).propagueErrorAndCompleteTo<IssueOwner>(toReturn).subscribe(
      (quproListIssue: QuproList<IssueOwner>) => {
        if (quproListIssue && quproListIssue.elements && quproListIssue.elements.length > 0) {
          toReturn.next(quproListIssue.elements[0]);
        } else {
          toReturn.error(QuproServiceError.getNoDataError());
        }
      }
    );
    return toReturn;
  }

  public getCurrentLoggedUsername(): string {
    return this.userService.getLoggedUserName();
  }

  public clearAllCaches() {
    this.quproService.clearAllCaches();
  }

  public getOwnerExtendedDataCached(): Observable<OwnerExtendedData> {
    const toReturn: QuproObservable<OwnerExtendedData> = new QuproObservable<OwnerExtendedData>();
    const ownerExtendedData: OwnerExtendedData = new OwnerExtendedData();
    const completeTimeoutForMemoryLeaks = 10000;
    this.userService.getLoggedUserDataCached()
    .subscribe((owner: BaseUser) => {
      ownerExtendedData.owner = owner;
      this.getPropietarioHouseCached(owner.owner_id)
      .subscribe((house: House) => {
        ownerExtendedData.house = house;
        this.getObraPisoPropietario(house.csite_id)
        .subscribe((csite: CsiteBase) => {
          ownerExtendedData.csite = csite;
          toReturn.next(ownerExtendedData);
          toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
        },
        (error: QuproServiceError) => {
          toReturn.error(error);
          toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
        });
      },
      (error: QuproServiceError) => {
        toReturn.error(error);
        toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
      });
    },
    (error: QuproServiceError) => {
      toReturn.error(error);
      toReturn.completeTimeout(completeTimeoutForMemoryLeaks);
    });
    return toReturn;
  }

  public getOwnerUserManualURL(csiteId: number): UrlFileDownloadData {
    const filename: string =
      this.messagesService.getUserManualDownloadFilename(this.messagesService.getDatetimeLocaleString(), Constants.PDF_FILE_EXTENSION);
    return {
           url: this.EXPOSED_QUPRO_URL + this.USER_MANUAL_PATH_TEMPLATE.replace('{csite_id}', ''+csiteId).replace('{filename}', filename),
           filename
          };
  }

  public getIssuesPdfBase64(filter: IssuesFilterOwner, order: IssuesOrderOwner): QuproObservable<DocumentReport> {
    return this.issueService.getIssuesOnwerReport(filter, order);
  }

  public getOwnerAppoiments(): Promise<any> {
    return new Promise( (resolve: (value: AppoimentViewModel[]) => void, reject: (error: QuproServiceError) => void) => {
      this.issueService.getAppoimentsDatesOwner(0, 0).subscribe(
        (appoiments: QuproList<AppoimentData>) => {
          if (appoiments && appoiments.elements && appoiments.elements.length > 0) {
            const toReturn: AppoimentViewModel[] = [];
            for (const appoimentData of appoiments.elements) {
              if (appoimentData) {
                const toAdd: AppoimentViewModel = this.populateService.populateAppoimentViewModel(appoimentData);
                if (toAdd) {
                  toReturn.push(toAdd);
                }
              }
            }
            resolve(toReturn);
          } else {
            resolve([]);
          }
        },
        (error: QuproServiceError) => {
          reject(error);
        }
      );
    });
  }

  public ownerAgreement(issuesIds: number[], base64: string, mimetype: string): Observable<OwnerAgreementUploaded> {
    return this.agreementService.uploadOwnerAgreement(issuesIds, base64, mimetype);
  }

  public secureResetPassword(loginUser: string, resetPasswordUrl: string, captchaResponseToken: string, baseUrlForResetPassword: string = null): Observable<object> {
    const captchaAlias: string = environment.recaptchaApiKeyAlias;
    return this.userService.secureResetPassword(loginUser, resetPasswordUrl, captchaAlias, captchaResponseToken, baseUrlForResetPassword);
  }

  public cerrarSesion() {
    this.userService.cerrarSesion();
  }

}
