import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'issue-comment',
  templateUrl: './issue-comment.component.html',
  styleUrls: ['./issue-comment.component.scss'],
})
export class IssueCommentComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() values: string[] = [];

  constructor(
  ) { }

  ngOnInit() {
  }

  hasCommentInValues(): boolean {
    const cleanedArray: string[] = this.getCleanArrayValues();
    const toReturn = cleanedArray ? cleanedArray.length > 0 : false;
    return toReturn;
  }

  getCleanArrayValues(): string[] {
    const toReturn: string[] = [];
    if (this.values) {
      for (const comment of this.values) {
        if (comment && comment.replace(' ', '') !== '') {
          toReturn.push(comment);
        }
      }
    }
    return toReturn;
  }

}
