import { Component, OnInit, Inject } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ThemeInfoService } from './services/theme-info.service';
import { ThemeInfo } from './models/themeInfo';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { PortalPropietarioService } from './services/portal-propietario.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesInfo } from '../assets/i18n/languages-info';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    @Inject(DOCUMENT) private document: Document,
    private modalController: ModalController,
    private portalPropietarioService: PortalPropietarioService,
    private platformLocation: PlatformLocation,
    private translateService: TranslateService,
  ) {
    this.initializeApp();
    // Initialize BackButton Event.
    this.initBackButtonEvent();
    LanguagesInfo.initTranslateService(this.translateService);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.setModoVista();
    });
  }

  async ngOnInit() {
    const themeInfo: ThemeInfo = ThemeInfoService.load();
    if (themeInfo) {
      if (themeInfo.cssText) {
        this.document.documentElement.style.cssText = themeInfo.cssText;
      }
    }
    const currentUsename: string = this.portalPropietarioService.getCurrentLoggedUsername();
    if (currentUsename) {
      LanguagesInfo.loadLanguageByUser(currentUsename);
    }
  }

  initBackButtonEvent() {
    this.platform.backButton.subscribe(async () => {
        let closedModal = false;
        try {
          const element = await this.modalController.getTop();
          if (element) {
            element.dismiss();
            closedModal = true;
          }
        } catch (error) {
          this.portalPropietarioService.logger.error('[AppComponent.backButton] Error al obtener el modal top.', error);
        }
        if (!closedModal) {
          this.platformLocation.back();
        }
    });
  }

  setModoVista() {
    const modoVista = localStorage.getItem('modoVista');
    if (!modoVista) {
      const platformsSupperted = this.platform.platforms();
      if (platformsSupperted.includes('desktop') || platformsSupperted.includes('tablet')) {
        localStorage.setItem('modoVista', 'escritorio');
      } else {
        localStorage.setItem('modoVista', 'mobile');
      }
    }
  }

}
