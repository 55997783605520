import { Component, OnInit, Input } from '@angular/core';
import { PortalPropietarioService } from '../../services/portal-propietario.service';
import { OwnerExtendedData } from '../../services/dto/owner.extended.data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public infoCuenta = {
    propietarioFullname: '',
    portal: '',
    piso: '',
    letra: '',
    obra: ''
  };

  public propietarioId: number;
  public obraId: number;
  public zonaComun: number;

  constructor(private portalPropietarioService: PortalPropietarioService) { }

  @Input() title: string;

  ngOnInit() {
    this.portalPropietarioService.getOwnerExtendedDataCached().subscribe(
      (ownerExtendedData: OwnerExtendedData) => {
        this.infoCuenta.propietarioFullname = ownerExtendedData.owner.firstname + ' ' +
        ownerExtendedData.owner.lastname;
        this.propietarioId = ownerExtendedData.owner.owner_id;
        this.infoCuenta.portal = ownerExtendedData.house.portal;
        this.infoCuenta.piso = ownerExtendedData.house.floor;
        this.infoCuenta.letra = ownerExtendedData.house.letter;
        this.obraId = ownerExtendedData.house.csite_id;
        this.zonaComun = ownerExtendedData.house.common_area;
        this.infoCuenta.obra = ownerExtendedData.csite.name;
      }
    );
  }
}
