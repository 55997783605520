import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { AdministrativeQueryCategory } from '../../../models/administrative-query-category';
import { RestAdministrativeQueryCategoriesResponse } from '../../../models/rest-responses';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { UiService} from '../../../services/ui.service';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
})
export class CategoriasComponent implements OnInit {

  categories: AdministrativeQueryCategory[] = [];
  @Output() categorySelectedEvent: EventEmitter<string> = new EventEmitter<string>();
  categorySelectedUniqueKey: string;

  constructor(private uiService: UiService, private administrativeQueriesService: AdministrativeQueriesService) { }

  ngOnInit() {
    this.administrativeQueriesService.getAdministrativeQueryCategories().subscribe((receivedCategories: RestAdministrativeQueryCategoriesResponse) => {
      this.categories = receivedCategories.administrativeQueryCategories;
    },error => {
      console.log(error['status']);
      if (error['status'] === 500) {
          this.uiService.onLoginRedirect();
      }else if (error['status'] === 401) {
        this.uiService.showErrorFromServiceErrorOnDownload(error, 'CategoriasComponent');
      } else {
        this.uiService.showErrorFromServiceErrorOnDownload(error, 'CategoriasComponent');
      }
  });

  }

  selectedCategory() {
    this.categorySelectedEvent.emit(this.categorySelectedUniqueKey);
  }

}
