import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CreateAdministrativeQueryModalComponent } from '../create-administrative-query-modal/create-administrative-query-modal.component';
import { InputAdministrativeQuery } from '../../../models/input-administrative-query';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service'
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { OwnerExtendedData } from '../../../services/dto/owner.extended.data';

@Component({
  selector: 'app-header-administrative-queries',
  templateUrl: './header-administrative-queries.component.html',
  styleUrls: ['./header-administrative-queries.component.scss'],
})
export class HeaderAdministrativeQueriesComponent implements OnInit {

  @Input() isMobile: boolean;
  ownerManageAdministrativeQueries: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private administrativeQueriesService: AdministrativeQueriesService,
    private portalPropietarioService: PortalPropietarioService
    ) { }

  ngOnInit() {
    this.portalPropietarioService.getOwnerExtendedDataCached().subscribe(
      (ownerExtendedData: OwnerExtendedData) => {
        this.ownerManageAdministrativeQueries = ownerExtendedData.owner.owner_administrative_queries_allowed_actions.includes('owner_view_administrative_queries');
      }
    );

  }

  async openAdministrativeQueryModal() {
    const modal = await this.modalCtrl.create({
      component: CreateAdministrativeQueryModalComponent,
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();

    // console.log('data recibida: ', data);
    // console.log('inputAdministrativeQueryFollowup: ', data.data);
    let inputAdministrativeQuery: InputAdministrativeQuery = new InputAdministrativeQuery();
    if (data !== undefined) {
      inputAdministrativeQuery = data.data;
      
      if (data.role === 'create') {
        this.administrativeQueriesService.createAdministrativeQueryAndFirstFollowup(inputAdministrativeQuery).subscribe((resp) => {
          this.administrativeQueriesService.createdAdministrativeQueryEvent.emit(true);
        });
      }
    }
  }

  searchAdministrativeQueries(event) {
    const subjectToSearch: string = event.target.value.toLowerCase();
    this.administrativeQueriesService.searchAdministrativeQueryEvent.emit(subjectToSearch);

  }

}
