import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { ThemeInfoService } from '../../services/theme-info.service';
import { ThemeInfo } from '../../models/themeInfo';
import { QuproServiceError, ResetPasswordService, TokenInfo } from '@arpada/arp-lib-common-qupro';
import { LanguagesInfo } from '../../../assets/i18n/languages-info';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {

  private static readonly TOKEN_QUERY_PARAM_KEY = 'token';
  private static readonly REDIRECT_AFTER_CHANGED_PASSWORD_TIMEOUT = 10 * 1000;

  private themeInfo: ThemeInfo = ThemeInfoService.DEFAULT_THEME_INFO;
  private tokenInfo: TokenInfo = null;
  logoUrl: string = ThemeInfoService.DEFAULT_THEME_INFO.logoUrl;
  isLoadingToken: boolean = true;
  isLoadingReset: boolean = false;
  isCorrectToken: boolean = false;
  isAfterChange: boolean = false;
  showRedirectPanel: boolean = false;
  passwordsEmpty: boolean = false;
  passwordsMatch: boolean = true;
  resetPasswordData = {
    email: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(HttpClient) private httpClient: HttpClient,
              @Inject(Platform) public platform: Platform,
              private resetPasswordService: ResetPasswordService,
              private route: ActivatedRoute,
              private uiService: UiService,
              private messageService: MessagesService,
              ) {
  }

  async ngOnInit() {
    const oldThemeInfo: ThemeInfo = ThemeInfoService.load();
    if (this.isWeb()) {
      this.themeInfo = await ThemeInfoService.selectThemeFromURL(this.httpClient);
    } else if (oldThemeInfo) {
      this.themeInfo = oldThemeInfo;
    }
    this.assignThemeInfo(this.themeInfo);
    this.route.queryParams.subscribe((params: { [x: string]: string; }) => {
      const tokenToCheck: string = params[ResetPasswordPage.TOKEN_QUERY_PARAM_KEY];
      if(tokenToCheck && tokenToCheck.replace(' ', '').length > 0){
        this.resetPasswordService.getTokenInfo(tokenToCheck).subscribe(
          (tokenInfo: TokenInfo)=>{
            this.tokenInfo = tokenInfo;
            this.isLoadingToken = false;
            this.isCorrectToken = true;
            LanguagesInfo.loadLanguageByUser(tokenInfo.reset_email);
            this.resetPasswordData.email = tokenInfo.reset_email;
          },
          (error: QuproServiceError)=>{
            this.isLoadingToken = false;
            console.error(error.message);
          }
        );
      } else {
        this.isLoadingToken = false;
      }
    });
  }

  private assignThemeInfo(themeInfoToAssign: ThemeInfo, now: boolean = true) {
    if (themeInfoToAssign) {
      if (now) {
        this.logoUrl = themeInfoToAssign.logoUrl;
        this.document.documentElement.style.cssText = themeInfoToAssign.cssText;
      }
      ThemeInfoService.save(themeInfoToAssign);
    }
  }

  isWeb(): boolean {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  doResetPassword(resetPasswordForm: NgForm ){
    this.passwordsEmpty = false;
    this.passwordsMatch = true;
    if(this.tokenInfo && this.tokenInfo.reset_token){
      if (this.resetPasswordData.newPassword && this.resetPasswordData.newPassword.replace(' ', '').length > 0) {
        if (this.resetPasswordData.newPassword == this.resetPasswordData.newPasswordConfirmation) {
          this.isLoadingReset = true;
          this.resetPasswordService.resetPasswordWithToken(
            this.tokenInfo.reset_token, this.resetPasswordData.newPassword, this.resetPasswordData.newPasswordConfirmation).subscribe(
              (result: string) => {
                this.isLoadingReset = false;
                if(this.tokenInfo.portal_redirect_url && this.tokenInfo.portal_redirect_url.replace(' ', '').length > 0){
                  this.showRedirectPanel = true;
                  setTimeout(() => {
                    this.doRedirectAfterChangedPassword();
                  }, ResetPasswordPage.REDIRECT_AFTER_CHANGED_PASSWORD_TIMEOUT)
                }
                this.isAfterChange = true;
              }, 
              (error: QuproServiceError) => {
                this.isLoadingReset = false;
                this.uiService.presentAlert(
                  this.messageService.getResetPasswordPageUnexpectedServerErrorHeader(),
                  null, 
                  error.message);
              }
            )
        } else {
          this.passwordsMatch = false;
        }
      } else {
        this.passwordsEmpty = true;
      }
    } else {
      this.uiService.presentAlert(this.messageService.getResetPasswordPageErrorHeader(),
        null, this.messageService.getResetPasswordPageErrorNoToken());
    }
  }

  doRedirectAfterChangedPassword(){
    window.location.href = this.tokenInfo.portal_redirect_url;
  }

}
