import { environment } from '../environments/environment';
import { NgModule, Inject, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuproBaseModule } from '@arpada/arp-lib-common-qupro';
import { ComponentsModule } from './components/components.module';
import { LoginPageModule } from './pages/login/login.module';
import { UiService } from './services/ui.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { PortalErrorService } from './services/portal-error.service';
import { ResetPasswordPageModule } from './pages/reset-password/reset-password.module';
import { PortalPropietarioService } from './services/portal-propietario.service';

let UI_SERVICE: UiService = null;
let PORTAL_PROPIETARIO_SERVICE: PortalPropietarioService = null;
let PORTAL_ERROR_SERVICE: PortalErrorService = null;

export function onLoginRedirectCallback()  {
  if (PORTAL_PROPIETARIO_SERVICE) {
    PORTAL_PROPIETARIO_SERVICE.cerrarSesion();
  }
  if (UI_SERVICE) {
    UI_SERVICE.onLoginRedirect();
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class ServerErrorHandler implements ErrorHandler {
  handleError(error) {
    console.error(error);
    if(PORTAL_ERROR_SERVICE){
      PORTAL_ERROR_SERVICE.manageError(error)
    }
  }
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    QuproBaseModule.forRoot({ loginRedirectCallback: onLoginRedirectCallback,
        userResetPassword: '',
        passwordUserResetPassword: '',
        apiURL: environment.apiURL,
        apiToken: environment.apiToken,
        apiMaxSessionTime: environment.apiMaxSessionTime,
        apiMaxCachedTime: environment.apiMaxCachedTime,
    }),
    LoginPageModule,
    ResetPasswordPageModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    File,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: ServerErrorHandler}
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(UiService) private uiService: UiService,
    private portalErrorService: PortalErrorService,
    private portalPropietarioService: PortalPropietarioService,
  ) {
    UI_SERVICE = this.uiService;
    PORTAL_ERROR_SERVICE = this.portalErrorService;
    PORTAL_PROPIETARIO_SERVICE = this.portalPropietarioService;
  }
}
