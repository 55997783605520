import { Component, OnInit, Input, Inject, EventEmitter } from '@angular/core';
import { IssueViewModel } from '../../../models/issueViewModel';
import { ModalController, AlertController } from '@ionic/angular';
import { IssueDetailedComponent } from '../issue-detailed/issue-detailed.component';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';
import { QuproServiceErrorType, QuproServiceError, IssueOwner, IssueUpdateDataValidation,
        OwnerAgreementUploaded } from '@arpada/arp-lib-common-qupro';
import { UiService } from '../../../services/ui.service';
import { MessagesService } from '../../../services/messages.service';
import { SignatureWidgetComponent, SignedData } from '../../signature-widget/signature-widget.component';

@Component({
  selector: 'issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
})
export class IssueCardComponent implements OnInit {

  @Input() issue: IssueViewModel;
  @Input() allDetails = false;
  txtNoConforme = '';

  processingRequest = false;
  loadingOwnerAgreement = false;
  ownerComment: string;
  

  constructor(
    private alertCtrl: AlertController,
    private modalController: ModalController,
    @Inject(PortalPropietarioService) private portalPropietarioService: PortalPropietarioService,
    @Inject(UiService) private uiService: UiService,
    @Inject(MessagesService) private messagesService: MessagesService,
    ) { }

  ngOnInit() {}

  async openIssueDetail() {
    if (!this.allDetails) {
      const modal = await this.modalController.create({
        component: IssueDetailedComponent,
        componentProps: {
          issue: this.issue,
        }
      });
      return await modal.present();
    }
  }

  issueHasComments(): boolean {
    return (this.issue.ownerComment || this.issue.stateComment || this.issue.postsaleAdminComment
            || this.issue.postsaleTechnicianComment || this.issue.propertyDeveloperComment) ? true : false;
  }

  async ownerConform(conform: boolean, event: Event) {
    event.stopPropagation();
    if (!conform) {
      const okButtonLabel: string = this.messagesService.getOkButtonLabel();
      const cancelButtonLabel: string = this.messagesService.getCancelButtonLabel();
      const notConformHeaderLabel: string = this.messagesService.getNotConformHeaderLabel();
      const notConformSubHeaderLabel: string = this.messagesService.getNotConformSubHeaderLabel();
      const notConformTextInputPlaceHolder: string = this.messagesService.getNotConformTextInputPlaceHolder();
      const input = await this.alertCtrl.create({
        backdropDismiss: false,
        header: notConformHeaderLabel,
        subHeader: notConformSubHeaderLabel,
        inputs: [
          {
            name: 'txtNoConforme',
            type: 'text',
            placeholder: notConformTextInputPlaceHolder,
          }
        ],
        buttons: [
          {
            text: cancelButtonLabel,
            role: 'cancel',
            handler: (blah) => {
            }
          }, {
            text: okButtonLabel,
            handler: (data) => {
              this.txtNoConforme = data.txtNoConforme;
              this.addNoConformComment(this.txtNoConforme);
            }
          }
        ]
      });
      await input.present();
    } else {
      this.conformOk();
    }
  }

  async conformOk() {
    const signedEvent: EventEmitter<SignedData> = new EventEmitter();
    const modal = await this.modalController.create({
      component: SignatureWidgetComponent,
      componentProps: {
        infoText: this.messagesService.getOwnerConformInfoText(this.issue.id),
        signed: signedEvent,
      }
    });
    signedEvent.subscribe((eventData: SignedData) => {
      this.loadingOwnerAgreement = true;
      this.portalPropietarioService.ownerAgreement([this.issue.id], eventData.base64, eventData.mimetype).subscribe(
        (ownerAgreementUploaded: OwnerAgreementUploaded) => {
          this.uiService.logger.debug('[IssueCardComponent.conformOk] Done: ', ownerAgreementUploaded);
          this.updateIssueData();
          this.loadingOwnerAgreement = false;
          this.issue.ownerConform = false;
        },
        (error: QuproServiceError) => {
          this.uiService.logger.error('[IssueCardComponent.conformOk] Error: ', error);
          if (error && error.type !== QuproServiceErrorType.UNATHORIZED && error.type !== QuproServiceErrorType.QUPRO_ERROR) {
            this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
          } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
            this.uiService.showSimpleMiddleToast(error.message, 5000);
          }
          this.loadingOwnerAgreement = false;
        }
      );
    });
    return await modal.present();
  }

  addNoConformComment(txtNoConforme: string) {
    this.portalPropietarioService.updateIssue(this.issue.id,
      {owner_comment: txtNoConforme, validation: IssueUpdateDataValidation.NO}).subscribe(
      (obj: string) => {
        this.uiService.showSimpleBottomToast(this.messagesService.getSuccessNotConformOwnerMessage(), 2000);
        this.updateIssueData();
      },
      (error: QuproServiceError) => {
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED && error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(error.message, 5000);
        }
      }
    );
  }

  ownerAddComment(comment: string, event: Event) {
    event.stopPropagation();
    this.processingRequest = true;
    this.portalPropietarioService.updateIssue(this.issue.id, {owner_comment_to_add: comment}).subscribe(
      (response: string) => {
        if (response && response.toLocaleLowerCase() === 'ok') {
          this.updateIssueData();
          this.ownerComment = '';
          comment = '';
        } else {
          this.processingRequest = false;
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
        }
      },
      (error: QuproServiceError) => {
        this.processingRequest = false;
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED && error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotUpdateIssueError(), 5000);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(error.message, 5000);
        }
      }
    );
  }

  updateIssueData() {
    this.portalPropietarioService.getIssue(this.issue.id).subscribe(
      (issue: IssueOwner) => {
        this.uiService.fireUpdateIssuestEvent([issue]);
        this.processingRequest = false;
      },
      (error: QuproServiceError) => {
      this.processingRequest = false;
        if (error && error.type !== QuproServiceErrorType.UNATHORIZED &&
            error.type !== QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(this.messagesService.getCanNotGetIssueError(), 5000);
        } else if (error && error.type === QuproServiceErrorType.QUPRO_ERROR) {
          this.uiService.showSimpleMiddleToast(error.message, 5000);
        }
      },
    );
  }

}
