import { Component, OnInit, Input, ViewChild, AfterViewChecked, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { AdministrativeQueryFollowup } from '../../../models/administrative-query-followup';
import { Subscription } from 'rxjs';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';

@Component({
  selector: 'app-followups',
  templateUrl: './followups.component.html',
  styleUrls: ['./followups.component.scss'],
})
export class FollowupsComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {


  @Input() administrativeQueryFollowups: AdministrativeQueryFollowup[];
  @Input() administrativeQuery: AdministrativeQuery;
  @Input() isVistaMobile: boolean;
  @ViewChild('scrollMe', {read: ElementRef, static: false}) followupLista: ElementRef;
  disableScrollDown = false
  private goToBottomEventSubscription: Subscription;

  constructor(private administrativeQueriesService: AdministrativeQueriesService) { }


  ngOnInit() {
    this.administrativeQuery = this.administrativeQueryFollowups[0].administrative_query;
    this.goToBottomEventSubscription = this.administrativeQueriesService.goToBottomEvent.subscribe(() => {
      // console.log('goToBottomEvent');
      this.scrollToBottom();
    });
  }

    ngAfterViewChecked() {        
        this.scrollToBottom();        
    }

    ngAfterViewInit() { 
      // this.scrollToBottom(); 
      // this.followupLista.nativeElement.scrollTo(0, this.followupLista.nativeElement.scrollHeight);

    }
    ngOnDestroy(): void {
      if (this.goToBottomEventSubscription) {
        this.goToBottomEventSubscription.unsubscribe();
      }
    }

    scrollToBottom(): void {
      this.followupLista.nativeElement.scroll({
        top: this.followupLista.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }

}
