import { Injectable } from '@angular/core';
import { ThemeInfo } from '../models/themeInfo';
import { HttpClient } from '@angular/common/http';
import { ThemeInfoMapService } from './theme-info-map.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParamThemeResponse {
  logo: string;
  cssText: string;
}
export class ThemeInfoService {

  private static readonly THEME_INFO_KEY = 'themeInfo';
  private static readonly THEME_URL_PARAM_KEY = 'theme=';
  private static readonly THEME_FILE_PREFIX = environment.baseUrl + 'assets/customThemes/';
  private static readonly THEME_LOGO_PREFIX = environment.baseUrl + 'assets/customThemes/logos/';
  private static readonly THEME_LOGO_DEFAULT_NAME = 'logo-default.png';
  private static readonly THEME_LOGO_DEFAULT_PATH: string = ThemeInfoService.THEME_LOGO_PREFIX + ThemeInfoService.THEME_LOGO_DEFAULT_NAME;
  public static readonly DEFAULT_THEME_INFO: ThemeInfo = new ThemeInfo(null, ThemeInfoService.THEME_LOGO_DEFAULT_PATH);
  private static readonly GET_FILE_TEXT_HEADERS: object = {responseType: 'text'};

  constructor() {
  }

  public static save(themeInfo: ThemeInfo) {
    localStorage.setItem(ThemeInfoService.THEME_INFO_KEY, JSON.stringify(themeInfo));
  }

  public static load(): ThemeInfo {
    return JSON.parse(localStorage.getItem(ThemeInfoService.THEME_INFO_KEY));
  }

  public static async selectThemeFromURL(httpClient: HttpClient): Promise<ThemeInfo> {
    let themeCss: string = null;
    let logoUrl: string = null;
    const infoMap = ThemeInfoMapService.THEME_HOST_MAP[location.hostname];
    if (infoMap) {
      const hostnameTheme = ThemeInfoService.THEME_FILE_PREFIX + infoMap.theme;
      const fileText = await this.getFile(httpClient, hostnameTheme, ThemeInfoService.GET_FILE_TEXT_HEADERS);
      if (fileText) {
        themeCss = fileText;
        logoUrl = ThemeInfoService.THEME_LOGO_PREFIX + infoMap.logo;
      }
    } else {
      const paramThemeResponse: ParamThemeResponse = await
                this.getThemeFromUrlParamsLocation(httpClient, location, ThemeInfoService.THEME_FILE_PREFIX);
      if (paramThemeResponse) {
        logoUrl = ThemeInfoService.THEME_LOGO_PREFIX + paramThemeResponse.logo;
        themeCss = paramThemeResponse.cssText;
      } else {
        logoUrl = ThemeInfoService.THEME_LOGO_PREFIX + ThemeInfoService.THEME_LOGO_DEFAULT_NAME;
        themeCss = null;
      }
    }
    const themeInfo: ThemeInfo = new ThemeInfo(themeCss, logoUrl);
    return themeInfo;
  }

  public static async selectThemeFromParam(httpClient: HttpClient, keyParam: string): Promise<ThemeInfo> {
    let logoUrl: string = ThemeInfoService.THEME_LOGO_PREFIX + ThemeInfoService.THEME_LOGO_DEFAULT_NAME;
    let themeCss: string = null;
    const paramThemeResponse: ParamThemeResponse =
    await ThemeInfoService.getThemeFromKey(httpClient, ThemeInfoService.THEME_FILE_PREFIX, keyParam);
    if (paramThemeResponse) {
      logoUrl = ThemeInfoService.THEME_LOGO_PREFIX + paramThemeResponse.logo;
      themeCss = paramThemeResponse.cssText;
    }
    const themeInfo: ThemeInfo = new ThemeInfo(themeCss, logoUrl);
    return themeInfo;
  }


  private static async getThemeFromUrlParamsLocation(httpClient: HttpClient,
                                                     location: Location, prefix: string): Promise<ParamThemeResponse> {
    const urlParams: string = location.search;
    let toReturn: ParamThemeResponse = null;
    if (urlParams) {
      const themeSplittedParams: string[] = urlParams.split(ThemeInfoService.THEME_URL_PARAM_KEY);
      if (themeSplittedParams && themeSplittedParams.length > 1) {
        const rightSide: string = themeSplittedParams[1];
        if (rightSide) {
          const rightSideSplitted = rightSide.split('&');
          if (rightSideSplitted && rightSideSplitted.length > 0) {
            const paramValue = rightSideSplitted[0];
            toReturn = await this.getThemeFromKey(httpClient, prefix, paramValue);
          }
        }
      }
    }
    return toReturn;
  }

  private static async getThemeFromKey(httpClient: HttpClient, prefix: string, key: string): Promise<ParamThemeResponse> {
    const infoMap = ThemeInfoMapService.THEME_PARAM_MAP[key];
    let toReturn: ParamThemeResponse = null;
    if (infoMap) {
      const paramTheme: string = prefix + infoMap.theme;
      const fileTheme: string = await ThemeInfoService.getFile(httpClient, paramTheme, ThemeInfoService.GET_FILE_TEXT_HEADERS);
      if (fileTheme) {
        const response: ParamThemeResponse = new ParamThemeResponse();
        response.logo = infoMap.logo;
        response.cssText = fileTheme;
        toReturn = response;
      }
    }
    return toReturn;
  }

  private static getFile(httpClient: HttpClient, file: string, headers: object): Promise<string> {
    return new Promise<string>(resolve => {
      httpClient.get(file, headers)
        .subscribe( resp => {
          if (resp) {
            resolve(resp.toString());
          } else {
            resolve('');
          }
      }, error => {
        resolve(null);
      });
    });
  }
}
