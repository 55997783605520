import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsLibModule } from '@arpada/arp-shared-comp-qupro';

import { SignaturePadModule } from 'angular2-signaturepad';
import { RecaptchaModule } from 'ng-recaptcha';

import { CalendarModule } from 'ion2-calendar';

import { IssueCardComponent } from './issue/issue-card/issue-card.component';
import { IssueCommentComponent } from './issue/issue-comment/issue-comment.component';
import { IssueDetailedComponent } from './issue/issue-detailed/issue-detailed.component';
import { IssueImageComponent } from './issue/image/issue.image.component';
import { IssuesCardListComponent } from './issues/issues-card-list/issues-card-list.component';
import { HeaderComponent } from './header/header.component';
import { CambioPasswordComponent } from './cambio-password/cambio-password.component';
import { ConsultasAdministrativasDesktopComponent } from './consultas-administrativas/consultas-administrativas-desktop/consultas-administrativas-desktop.component';
import { ConsultasAdministrativasMobileComponent } from './consultas-administrativas/consultas-administrativas-mobile/consultas-administrativas-mobile.component';
import { ActionsIssuesHeaderComponent } from './issues/actions-issues-header/actions-issues-header.component';
import { ActionsIssuesMoreActionsComponent } from './issues/actions-issues-header/more-actions-issues/more-actions-issues.component';
import { FilterIssuesComponent } from './issues/actions-issues-header/filter-issues/filter-issues.component';
import { OrderIssuesComponent } from './issues/actions-issues-header/order-issues/order-issues.component';
import { IssuesCompactListComponent } from './issues/issues-compact-list/issues-compact-list.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignatureWidgetComponent } from './signature-widget/signature-widget.component';
import { AdministrativeQueryComponent } from './consultas-administrativas/administrative-query/administrative-query.component';
import { FollowupComponent } from './consultas-administrativas/followup/followup.component';
import { FollowupsComponent } from './consultas-administrativas/followups/followups.component';
import { HeaderAdministrativeQueriesComponent } from './consultas-administrativas/header-administrative-queries/header-administrative-queries.component';
import { AdministrativeQueriesComponent } from './consultas-administrativas/administrative-queries/administrative-queries.component';
import { CreateAdministrativeQueryModalComponent } from './consultas-administrativas/create-administrative-query-modal/create-administrative-query-modal.component';
import { HeaderFollowupsComponent } from './consultas-administrativas/header-followups/header-followups.component';
import { FooterFollowupsComponent } from './consultas-administrativas/footer-followups/footer-followups.component';
import { FollowupsModalComponent } from './consultas-administrativas/followups-modal/followups-modal.component';
import { CategoriasComponent } from './consultas-administrativas/categorias/categorias.component';


import { PipesModule } from '../pipes/pipes.module';
import { CaptchaComponent } from './captcha/captcha.component';
import { UiService } from '../services/ui.service';



@NgModule({
  declarations: [
    IssuesCardListComponent,
    ActionsIssuesHeaderComponent,
    ActionsIssuesMoreActionsComponent,
    IssueCardComponent,
    IssueCommentComponent,
    IssueDetailedComponent,
    FilterIssuesComponent,
    OrderIssuesComponent,
    IssueImageComponent,
    HeaderComponent,
    CambioPasswordComponent,
    IssuesCompactListComponent,
    CaptchaComponent,
    SignatureWidgetComponent,
    ResetPasswordComponent,
    ConsultasAdministrativasDesktopComponent,
    ConsultasAdministrativasMobileComponent,
    AdministrativeQueryComponent,
    FollowupComponent,
    FollowupsComponent,
    HeaderAdministrativeQueriesComponent,
    AdministrativeQueriesComponent,
    CreateAdministrativeQueryModalComponent,
    HeaderFollowupsComponent,
    FooterFollowupsComponent,
    FollowupsModalComponent,
    CategoriasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    FormsModule,
    RecaptchaModule,
    CalendarModule,
    TranslateModule,
    ComponentsLibModule,
    SignaturePadModule,
  ],
  exports: [
    IssueCardComponent,
    IssuesCardListComponent,
    ActionsIssuesHeaderComponent,
    HeaderComponent,
    CambioPasswordComponent,
    IssuesCompactListComponent,
    CaptchaComponent,
    SignatureWidgetComponent,
    ResetPasswordComponent,
    ConsultasAdministrativasDesktopComponent,
    ConsultasAdministrativasMobileComponent,
],
  providers: [
    File,
    DatePipe,
    ScreenOrientation,
    { provide: 'LoginRedirectObservableEvent', useClass: UiService},
  ],
  entryComponents: [
    IssueDetailedComponent,
    CambioPasswordComponent,
    FilterIssuesComponent,
    OrderIssuesComponent,
    SignatureWidgetComponent,
    ResetPasswordComponent,
    CreateAdministrativeQueryModalComponent,
    FollowupsModalComponent
  ]
})
export class ComponentsModule { }
