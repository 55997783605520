import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { IssueViewModel } from '../../../models/issueViewModel';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../../services/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'issue-detailed-component',
  templateUrl: './issue-detailed.component.html',
  styleUrls: ['./issue-detailed.component.scss'],
})
export class IssueDetailedComponent implements OnInit, OnDestroy {

  @Input() issue: IssueViewModel;
  private loginRedirectSubscription: Subscription;

  constructor(
    @Inject(ModalController) private modalController: ModalController,
    @Inject(UiService) private uiService: UiService,
  ) { }

  ngOnInit() {
    // Bind login redirect close
    this.loginRedirectSubscription = this.uiService.subscribeToLoginRedirectEvent( () => {
      this.dismiss();
    });
  }

  ngOnDestroy() {
    if (this.loginRedirectSubscription) {
      this.loginRedirectSubscription.unsubscribe();
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
