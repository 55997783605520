import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService, QuproServiceError, QuproServiceErrorType } from '@arpada/arp-lib-common-qupro';
import { PortalPropietarioService } from '../services/portal-propietario.service';
import { UiService } from '../services/ui.service';
import { MessagesService } from '../services/messages.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {

  constructor(
    private userService: UserService,
    private router: Router,
    private portalPropietarioService: PortalPropietarioService,
    private uiService: UiService,
    private messagesService: MessagesService,
  ) { }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean  {
    try {
      // TODO: redireccionar al loading cuando se carga por primera vez
      return this.userService.isUserLogin((unexpectedError: Error) => {
        this.portalPropietarioService.logger.error('[LoginGuard] error inexperado obteniendo si el usuario esta logueado', unexpectedError);
        this.router.navigate(['login'], { replaceUrl: true });
      },
      (quproError: QuproServiceError) => {
        if (!quproError || quproError.type !== QuproServiceErrorType.UNATHORIZED) {
          // Distinto de Unathorized ya que la libreria hace automaticamente el redirect cuando es no autorizado
          this.showErrorIfLogin(quproError);
          this.portalPropietarioService.logger.error(
            '[LoginGuard] error quproServiceError obteniendo si el usuario esta logueado', quproError);
          this.router.navigate(['login'], { replaceUrl: true });
        }
      });
    } catch (error) {
      this.portalPropietarioService.logger.error(
        '[LoginGuard] error(Exception) obteniendo si el usuario esta logueado', error);
      this.router.navigate(['login'], { replaceUrl: true });
    }
  }

  private showErrorIfLogin(quproError: QuproServiceError) {
    if (this.router.url && this.router.url.endsWith('login')) {
      this.uiService.presentAlert(null, this.messagesService.getLoginErrorOnGuard(), quproError.message);
    }
  }
}
