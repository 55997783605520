import { Component, OnInit, Input } from '@angular/core';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';


@Component({
  selector: 'app-header-followups',
  templateUrl: './header-followups.component.html',
  styleUrls: ['./header-followups.component.scss'],
})
export class HeaderFollowupsComponent implements OnInit {

  @Input() administrativeQuery: AdministrativeQuery;

  constructor(private administrativeQueriesService: AdministrativeQueriesService) { }

  ngOnInit() {}

  scrollToBottom() {
    this.administrativeQueriesService.goToBottomEvent.emit(true);
  }

}
