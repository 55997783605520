import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IssuesFilterOwner, IssuesOrderOwner } from '@arpada/arp-lib-common-qupro';
import { ModalController } from '@ionic/angular';
import { FilterIssuesComponent } from './filter-issues/filter-issues.component';
import { OrderIssuesComponent } from './order-issues/order-issues.component';
import { ActionsSharedData } from './actions.shared.data';

export { ActionsSharedData } from './actions.shared.data';

@Component({
  selector: 'actions-issues-header',
  templateUrl: './actions-issues-header.component.html',
  styleUrls: ['./actions-issues-header.component.scss'],
})
export class ActionsIssuesHeaderComponent implements OnInit {

  @Input() sharedData: ActionsSharedData;
  @Input() filter: IssuesFilterOwner;
  @Input() order: IssuesOrderOwner;
  @Input() showCompactListButton: boolean;
  @Input() showCardListButton: boolean;

  @Output() public filterChange = new EventEmitter();
  @Output() public orderChange = new EventEmitter();
  @Output() public compactListPressed = new EventEmitter();
  @Output() public cardListPressed = new EventEmitter();

  constructor(
    @Inject(ModalController) private modalController: ModalController,
  ) { }

  ngOnInit() {}

  onCompactListPressed(event: Event) {
    this.compactListPressed.emit(event);
  }

  onCardListPressed(event: Event) {
    this.cardListPressed.emit(event);
  }

  async openFilterModal() {
    const modal = await this.modalController.create({
      component: FilterIssuesComponent,
      componentProps: {
        filter: this.filter,
        filterChange: this.filterChange,
        sharedData: this.sharedData,
      }
    });
    return await modal.present();
  }

  async openOrderModal() {
    const modal = await this.modalController.create({
      component: OrderIssuesComponent,
      componentProps: {
        order: this.order,
        orderChange: this.orderChange,
        sharedData: this.sharedData,
      }
    });
    return await modal.present();
  }
}
