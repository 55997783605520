import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams  } from '@angular/common/http';
import { UserCredentials } from '@arpada/arp-lib-common-qupro';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { InputAdministrativeQuery } from '../models/input-administrative-query'
import { InputAdministrativeQueryFollowup } from '../models/input-administrative-query-followup'
import { AdministrativeQuery } from '../models/administrative-query';


@Injectable({
    providedIn: 'root'
})
export class AdministrativeQueriesService {

    private static CATEGORIES_URL = '/get-administrative-query-categories';
    private static OWNER_ADMINISTRATIVE_QUERIES_URL = '/get-user-administrative-queries';
    private static OWNER_ADMINISTRATIVE_QUERY_FOLLOWUPS_URL = '/get-user-followups-from-administrative-query';
    private static DOWNLOAD_HMAC_URL = '/download-stored-file';
    private static CREATE_ADMINISTRATIVE_QUERY = '/create-administrative-query-and-first-followup';
    private static CREATE_FOLLOWUP = '/create-administrative-query-followup-from-portal-user';
    private static CREDENTIAL_SEPARATOR = ':';
    private static CREDENTIAL_PREFIX = 'TOKEN-';

    selectedAdministrativeQueryEvent = new EventEmitter();
    searchAdministrativeQueryEvent = new EventEmitter();
    createdAdministrativeQueryEvent = new EventEmitter();
    createdAdministrativeQueryFollowupEvent = new EventEmitter();
    goToBottomEvent = new EventEmitter();

    constructor(private httpClient: HttpClient){}

    public getFollowpsByAdministrativeQueryId(id: number): Observable<Object> {
        // const httpOptions = this.createHttpOptions();
        const httpOptions = {
            headers: new HttpHeaders({
                'app-key': environment.apiToken,
                Authorization: 'Basic ' + btoa(this.getUserCredentialToken()),
                }),
            params: new HttpParams().set('administrative_query_id', id.toString())
        }
        return this.httpClient.get(environment.fastapiExposedQuproQueriesBaseURL + AdministrativeQueriesService.OWNER_ADMINISTRATIVE_QUERY_FOLLOWUPS_URL, httpOptions);
    }

    public getUserAdministrativeQueries(): Observable<Object> {
        const httpOptions = this.createHttpOptions();
        return this.httpClient.get(environment.fastapiExposedQuproQueriesBaseURL + AdministrativeQueriesService.OWNER_ADMINISTRATIVE_QUERIES_URL, httpOptions);
    }

    public getAdministrativeQueryCategories(): Observable<Object> {

        const httpOptions = this.createHttpOptions();
        return this.httpClient.get(environment.fastapiExposedQuproQueriesBaseURL + AdministrativeQueriesService.CATEGORIES_URL, httpOptions);
    }

    public getHmacUrl(stored_file_uuid: string): Observable<Object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'app-key': environment.apiToken,
                Authorization: 'Basic ' + btoa(this.getUserCredentialToken()),
                }),
            params: new HttpParams().set('stored_file_uuid', stored_file_uuid)
        }
        return this.httpClient.get(environment.fastapiExposedQuproQueriesBaseURL + AdministrativeQueriesService.DOWNLOAD_HMAC_URL, httpOptions);
    }

    public createAdministrativeQueryAndFirstFollowup(inputAdministrativeQuery: InputAdministrativeQuery): Observable<Object> {

        const httpOptions = this.createHttpOptions();
        const body = inputAdministrativeQuery;
        return this.httpClient.post(environment.fastapiExposedQuproCommandsBaseURL + AdministrativeQueriesService.CREATE_ADMINISTRATIVE_QUERY, body, httpOptions);
    }

    public createAdministrativeQueryFollowup(inputAdministrativeQueryFollowup: InputAdministrativeQueryFollowup): Observable<Object> {
        const httpOptions = this.createHttpOptions();
        const body = inputAdministrativeQueryFollowup;
        return this.httpClient.post(environment.fastapiExposedQuproCommandsBaseURL + AdministrativeQueriesService.CREATE_FOLLOWUP, body, httpOptions);
    }

    public searchAdministrativeQueriesBySmartText(smartText: string): Observable<Object> {
        const httpOptions = {
            headers: new HttpHeaders({
                'app-key': environment.apiToken,
                Authorization: 'Basic ' + btoa(this.getUserCredentialToken()),
                }),
            params: new HttpParams().set('smart_text', smartText)
        }
        return this.httpClient.get(environment.fastapiExposedQuproQueriesBaseURL + AdministrativeQueriesService.OWNER_ADMINISTRATIVE_QUERIES_URL, httpOptions);
    }

    private createHttpOptions() {
        const userCredentialToken: string = this.getUserCredentialToken();
        const httpOptions = {
                headers: new HttpHeaders({
                'app-key': environment.apiToken,
                Authorization: 'Basic ' + btoa(userCredentialToken),
                })
        };
        return httpOptions;
    }

    private getUserCredentialToken(): string {
        const userCredentials: UserCredentials= UserCredentials.load();
        const userCredentialToken: string = userCredentials.getUsername() + AdministrativeQueriesService.CREDENTIAL_SEPARATOR + AdministrativeQueriesService.CREDENTIAL_PREFIX + userCredentials.getTokenPass();
        return userCredentialToken;
    }

}