import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdministrativeQuery } from '../../../models/administrative-query';
import { AdministrativeQueryFollowup } from '../../../models/administrative-query-followup';
import { AdministrativeQueriesService } from '../../../services/consultas-administrativas.service';
import { UiService } from '../../../services/ui.service';
import { RestAdministrativeQueryFollowupsResponse } from '../../../models/rest-responses';

@Component({
  selector: 'app-followups-modal',
  templateUrl: './followups-modal.component.html',
  styleUrls: ['./followups-modal.component.scss'],
})
export class FollowupsModalComponent implements OnInit, OnDestroy {

  @Input() administrativeQuery: AdministrativeQuery;
  @Input() administrativeQueryFollowups: AdministrativeQueryFollowup[];
  @Input() isVistaMobile: boolean;

  private createdAdministrativeQueryFollowupSubscription: Subscription;

  constructor(
    private uiService: UiService, 
    private administrativeQueriesService: AdministrativeQueriesService
    ) { }

  ngOnInit() {
    this.createdAdministrativeQueryFollowupSubscription = this.administrativeQueriesService.createdAdministrativeQueryFollowupEvent.subscribe((administrativeQuery: AdministrativeQuery) => {
      this.administrativeQueriesService.getFollowpsByAdministrativeQueryId(administrativeQuery.id).subscribe((receivedFollowups: RestAdministrativeQueryFollowupsResponse) => {
        this.administrativeQueryFollowups = receivedFollowups.administrativeQueryFollowups;
        // this.administrativeQueriesService.goToBottomEvent.emit(true);
      }, error => {
        console.log(error['status']);
        if (error['status'] === 500) {
            this.uiService.onLoginRedirect();
        }else if (error['status'] === 401) {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'FollowupsModalComponent');
        } else {
          this.uiService.showErrorFromServiceErrorOnDownload(error, 'FollowupsModalComponent');
        }
      });
    });
  }


  ngOnDestroy(): void {
    if (this.createdAdministrativeQueryFollowupSubscription) {
      this.createdAdministrativeQueryFollowupSubscription.unsubscribe();
    }
  }

}
