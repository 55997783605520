import { Injectable } from '@angular/core';
import { ErrorsBody, ErrorService, LocalStorageQuproLogger } from '@arpada/arp-lib-common-qupro';
import { Platform } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { stringify as FlattedStringify} from 'flatted';


let LAST_CALLBACK = null;
let TEMPORAL_ERRORS= [];

@Injectable({
    providedIn: 'root'
  })
export class PortalErrorService {

    constructor(
        private errorService: ErrorService,
        private platform: Platform,
        private device: DeviceDetectorService,
        public logger: LocalStorageQuproLogger,
    ){}

    public manageError(error: any){
        TEMPORAL_ERRORS.push(this.sanitizeError(error))
        if (LAST_CALLBACK) {
            try {
            window.clearTimeout(LAST_CALLBACK);
            } catch (error) {
            }
            LAST_CALLBACK = null;
        }
        LAST_CALLBACK = window.setTimeout(() => {
            this.sendLogToSever(TEMPORAL_ERRORS);
            LAST_CALLBACK = null;
            TEMPORAL_ERRORS = [];
        }, environment.timeToAccumulateErrors);
    }

    private sanitizeError(error: any): any{
        if (error) {
            let toReturn: any = null
            if(typeof error === 'object'){
                if(error.originalStack){
                    toReturn = error.originalStack;
                }
                if(error.message){
                    toReturn = error.message;
                }
                if(error.stack){
                    toReturn = error.stack;
                }
            }
            if(typeof error === 'string'){
                toReturn = error;
            }
            if(toReturn){
                return toReturn;
            } else {
                return FlattedStringify(error);
            }
        } else {
            return 'Error vacio';
        }
    }
    
    public sendLogToSever(errors: any) {
        const deviceInfo = {
            localStorage,
            loggerString: this.logger.getLoggerString(),
            device: this.device,
            extraInfo: 'width: ' + this.platform.width() + ', height: ' +
                        this.platform.height() + '; isLandscape?: ' + this.platform.isLandscape() +
                        '; isPortrait?: ' + this.platform.isPortrait() +
                        '; url: ' + this.platform.url()
                        ,
        };
        const objectToSend: ErrorsBody = {
            device_info: deviceInfo,
            errors,
            version: environment.version,
            app_logger_name: environment.appKeyName,
        };
        this.errorService.sendErrors(objectToSend);
    }
}