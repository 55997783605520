import { Component, OnInit, Input, Inject } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { environment } from '../../../../environments/environment';
import { IssueViewModel } from '../../../models/issueViewModel';
import { QuproList, IssuesService, PhotoIssue } from '@arpada/arp-lib-common-qupro';
import { IonCard } from '@ionic/angular';
import { PortalPropietarioService } from '../../../services/portal-propietario.service';

@Component({
  selector: 'issue-image',
  templateUrl: './issue.image.component.html',
  styleUrls: ['./issue.image.component.scss'],
})
export class IssueImageComponent implements OnInit {

  private static WIDTH_IMAGE = 495;
  readonly NO_PHOTO_URL: string =  environment.baseUrl + 'assets/noPhoto.png';
  readonly LOADING_PHOTO_URL: string = environment.baseUrl + 'assets/loadingPhoto.svg';
  base64ImagePrefix = 'data:image/jpeg;base64,';
  @Input() issuecard: IonCard;
  @Input() issue: IssueViewModel;
  baseUrl: string = environment.baseUrl;
  constructor(
    @Inject(File) private fileApi: File,
    private issueService: IssuesService,
    private portalPropietarioService: PortalPropietarioService,
  ) { }

  ngOnInit() {
  }

  ifShowIssuePhoto(): boolean {
    return (this.issue.hasPhoto && this.issue.photoLocalUrl && this.issue.photoLocalUrl.replace(' ', '') !== '');
  }

  ifShowNoPhoto(): boolean {
    return !this.issue.hasPhoto;
  }

  ifShowLoadingPhoto(): boolean {
    return (this.issue.hasPhoto && (!this.issue.photoLocalUrl || this.issue.photoLocalUrl.replace(' ', '') === ''));
  }

  loadImgWhenIsLoadingShowing() {
    this.issueService.getPhotoIssue(this.issue.id,
                                    IssueImageComponent.WIDTH_IMAGE,
                                    IssueImageComponent.WIDTH_IMAGE)
                                    .subscribe(this.getPhoto , this.getPhotoError);
  }

  getPhoto = (photo: QuproList<PhotoIssue>) => {
    if (photo && photo.elements && photo.elements.length > 0 && photo.elements[0] && photo.elements[0].photo) {
      this.issue.photoLocalUrl = this.base64ImagePrefix + photo.elements[0].photo;
    } else {
      this.issue.photoLocalUrl = null;
    }
  }

  getPhotoError = (error: any ) => {
    this.issue.photoLocalUrl = null;
  }

  randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public writeFile(base64Data: any, folderName: string, fileName: any) {
    const result = this.getContentType(base64Data);
    const content = result[1];
    const contentType = result[0];
    const DataBlob = this.base64toBlob(content, contentType);
    // here iam mentioned this line this.file.externalRootDirectory is a native pre-defined file path storage.
    // You can change a file path whatever pre-defined method.
    const filePath = this.fileApi.externalRootDirectory + folderName;
    this.fileApi.writeFile(filePath, fileName, DataBlob, contentType).then((success) => {
      this.portalPropietarioService.logger.log('File Writed Successfully', success);
    }).catch((err) => {
      this.portalPropietarioService.logger.error('[IssueImage.writeFile] Error Occured While Writing File', err);
    });
}
  // here is the method is used to get content type of an bas64 data
  public getContentType(base64Data: any) {
      const block = base64Data.split(':');
      const contentType = block[0].split(':')[1];
      return [contentType, block[1]];
  }
  // here is the method is used to convert base64 data to blob data
  public base64toBlob(b64Data, contentType) {
      contentType = contentType || '';
      const sliceSize = 512;
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {
          type: contentType
      });
      return blob;
  }

  /*
  public static getFile(httpClient: HttpClient, file: string, headers: object): Promise<string> {
    return new Promise<string>(resolve => {
      httpClient.get(file, headers)
        .subscribe( resp => {
          if (resp) {
            resolve(resp.toString());
          } else {
            resolve('');
          }
      }, error => {
        resolve(null);
      });
    });
  }*/
}
